import { Injectable } from '@angular/core';
import { Apollo, MutationResult, QueryRef, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArbeitsauftragService {

  constructor(private apollo: Apollo) { }

  public createArbeitsauftrag(teamId: string, schadenId: string, beschreibung?: string): Observable<MutationResult> {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation createArbeitsauftrag(
          $teamId: ID!,
          $schadenId: ID!,
          $beschreibung: String
        ) {
          createArbeitsauftrag(
            teamId: $teamId,
            schadenId: $schadenId,
            beschreibung: $beschreibung
          ) {
            id
            nummer
          }
        }
      `,
      variables: {
        teamId: teamId,
        schadenId: schadenId,
        beschreibung: beschreibung,
      }
    });
  }

  public deleteArbeitsauftragBySchadenId(schadenId: string) {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation deleteArbeitsauftragBySchadenId(
          $schadenId: ID!
        ) {
          deleteArbeitsauftragBySchadenId(
            schadenId: $schadenId
          ) {
            id
            nummer
            active
          }
        }
      `,
      variables: {
        schadenId: schadenId,
      }
    });
  }

  public arbeitsauftragErledigt(id: string, bemerkung: string): Observable<MutationResult> {
    return this.apollo.mutate({
      mutation: gql`
        mutation arbeitsauftragErledigt(
          $id: ID!,
          $bemerkung: String
        ) {
          arbeitsauftragErledigt(
            id: $id,
            bemerkung: $bemerkung
          ) {
            id
          }
        }
      `,
      variables: {
        id: id,
        bemerkung: bemerkung,
      }
    });
  }
}
