import { Injectable } from '@angular/core';
import { Apollo, MutationResult, QueryRef, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SchadenService {

  private _backlink: any;
  private _begehungId: string|unknown;
  private _strassenabschnitt: any = null;
  private _zubegehenStrassenabschnittId: string|unknown;
  private _prioritaetId: string|unknown;
  private _teilanlageId: string|unknown;
  private _schadenId: string|unknown;
  //private _schadensartenId: string[] = [];
  private _schadenartId: string|unknown;

  public datum: Date = new Date();
  public lon: number|unknown;
  public lat: number|unknown;

  public laenge: number|unknown;
  public breite: number|unknown;
  public tiefe: number|unknown;
  private _bemerkung: string|unknown;

  public fotos: Set<string> = new Set<string>();

  constructor(private apollo: Apollo) { }

  delete(schadenId: string): Observable<MutationResult> {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation deleteSchaden($id: ID!) {
          deleteSchaden(id: $id) {
            id
            active
          }
        }
      `,
      variables: {
        id: schadenId
      }
    });
  }

  createNew(): Observable<MutationResult> {
    console.debug("Schaden to save:", this);
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation createSchaden(
          $prioritaetId: ID!,
          $strassenabschnittId: ID,
          $teilanlageId: ID!,
          $schadenartId: ID!,
          $zubegehenStrassenabschnittId: ID,
          $datum: String!,
          $lon: Float,
          $lat: Float,
          $laenge: Float,
          $breite: Float,
          $tiefe: Float,
          $bemerkung: String
        ) {
          createSchaden(
            prioritaetId: $prioritaetId,
            strassenabschnittId: $strassenabschnittId,
            teilanlageId: $teilanlageId,
            schadenartId: $schadenartId,
            zubegehenStrassenabschnittId: $zubegehenStrassenabschnittId,
            datum: $datum,
            lon: $lon,
            lat: $lat,
            laenge: $laenge,
            breite: $breite,
            tiefe: $tiefe,
            bemerkung: $bemerkung
          ) {
            id
          }
        }
      `,
      variables: {
        prioritaetId: this.prioritaetId,
        strassenabschnittId: this.strassenabschnitt?.id,
        teilanlageId: this.teilanlageId,
        schadenartId: this._schadenartId,
        zubegehenStrassenabschnittId: this.zubegehenStrassenabschnittId,
        datum: this.datum,
        lon: this.lon,
        lat: this.lat,
        laenge: this.laenge,
        breite: this.breite,
        tiefe: this.tiefe,
        bemerkung: this._bemerkung,
      },
    });
  }

  createNewWithStrassenabschnitt(
    strassenabschnittId: string,
    prioritaetId: string,
    teilanlageId: string,
    schadenartId: string,
    datum: Date,
    lon: number,
    lat: number,
    laenge: number,
    breite: number,
    tiefe: number,
    bemerkung: string): Observable<MutationResult> {
    console.debug("Schaden to save:", this);
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation createSchaden(
          $prioritaetId: ID!,
          $strassenabschnittId: ID,
          $teilanlageId: ID!,
          $schadenartId: ID!,
          $datum: String!,
          $lon: Float,
          $lat: Float,
          $laenge: Float,
          $breite: Float,
          $tiefe: Float,
          $bemerkung: String
        ) {
          createSchaden(
            prioritaetId: $prioritaetId,
            strassenabschnittId: $strassenabschnittId,
            teilanlageId: $teilanlageId,
            schadenartId: $schadenartId,
            datum: $datum,
            lon: $lon,
            lat: $lat,
            laenge: $laenge,
            breite: $breite,
            tiefe: $tiefe,
            bemerkung: $bemerkung
          ) {
            id
          }
        }
      `,
      variables: {
        prioritaetId: prioritaetId,
        strassenabschnittId: strassenabschnittId,
        teilanlageId: teilanlageId,
        schadenartId: schadenartId,
        datum: datum,
        lon: lon,
        lat: lat,
        laenge: laenge,
        breite: breite,
        tiefe: tiefe,
        bemerkung: bemerkung,
      },
    });
  }

  createNewWithZubegehenStrassenabschnitt(
    zubegehenStrassenabschnittId: string,
    prioritaetId: string,
    teilanlageId: string,
    schadenartId: string,
    datum: Date,
    lon: number,
    lat: number,
    laenge: number,
    breite: number,
    tiefe: number,
    bemerkung: string): Observable<MutationResult> {
    console.debug("Schaden to save:", this);
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation createSchaden(
          $prioritaetId: ID!,
          $zubegehenStrassenabschnittId: ID,
          $teilanlageId: ID!,
          $schadenartId: ID!,
          $datum: String!,
          $lon: Float,
          $lat: Float,
          $laenge: Float,
          $breite: Float,
          $tiefe: Float,
          $bemerkung: String
        ) {
          createSchaden(
            prioritaetId: $prioritaetId,
            zubegehenStrassenabschnittId: $zubegehenStrassenabschnittId,
            teilanlageId: $teilanlageId,
            schadenartId: $schadenartId,
            datum: $datum,
            lon: $lon,
            lat: $lat,
            laenge: $laenge,
            breite: $breite,
            tiefe: $tiefe,
            bemerkung: $bemerkung
          ) {
            id
          }
        }
      `,
      variables: {
        prioritaetId: prioritaetId,
        zubegehenStrassenabschnittId: zubegehenStrassenabschnittId,
        teilanlageId: teilanlageId,
        schadenartId: schadenartId,
        datum: datum,
        lon: lon,
        lat: lat,
        laenge: laenge,
        breite: breite,
        tiefe: tiefe,
        bemerkung: bemerkung,
      },
    });
  }

  addFoto(foto: string, schadenId?: string): Observable<MutationResult> {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation addFoto(
          $schadenId: ID!,
          $foto: String
        ) {
          addFotoToSchaden(
            schadenId: $schadenId,
            foto: $foto
          ) {
            id
          }
        }
      `,
      variables: {
        schadenId: schadenId ? schadenId : this.schadenId,
        foto: foto,
      }
    });
  }

  findAllSchaeden(cache: boolean = false): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findAllSchaeden {
          findAllSchaeden {
            id
            datum
            createdAt
            userCreated
            modifiedAt
            userModified
            arbeitsauftrageVorhanden
            prioritaet {
              id
              name
            }
            teilanlage {
              id
              name
            }
            schadenart {
              id
              name
            }
            strassenabschnitt {
              id
              bezeichnung
            }
            zubegehenStrassenabschnitt {
              id
              bezeichnung
            }
          }
        }
      `,
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    });
  }

  findAllSchadenInDays(tage: number, inaktive: boolean = false, cache: boolean = false): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findAllSchaedenInDays($tage: Int!, $inaktive: Boolean) {
          findAllSchaedenInDays(
            tage: $tage,
            inaktive: $inaktive
          ) {
            id
            datum
            createdAt
            userCreated
            modifiedAt
            userModified
            arbeitsauftrageVorhanden
            active
            lon
            lat
            prioritaet {
              name
            }
            teilanlage {
              name
            }
            schadenart {
              name
            }
            strassenabschnitt {
              bezeichnung
            }
            zubegehenStrassenabschnitt {
              id
              bezeichnung
            }
          }
        }
      `,
      variables: {
        tage: tage,
        inaktive: inaktive
      },
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    });
  }

  findSchadenById(id: string): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findSchadenById($id: ID!) {
          findSchadenById(id: $id) {
            id
            datum
            createdAt
            userCreated
            modifiedAt
            userModified
            arbeitsauftrageVorhanden
            active
            prioritaet {
              name
            }
            teilanlage {
              name
            }
            strassenabschnitt {
              bezeichnung
            }
            schadenart {
              name
            }
            zubegehenStrassenabschnitt {
              bezeichnung
            }
            fotos {
              id
              active
              mimeType
              foto
            }
            laenge
            breite
            tiefe
          }
        }
      `,
      variables: {
        id: id
      }
    });
  }

  findAllSchadenInDaysSimple(tage: number, inaktive: boolean = false, cache: boolean = false): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findAllSchaedenInDaysSmall($tage: Int!, $inaktive: Boolean) {
          findAllSchaedenInDays(
            tage: $tage,
            inaktive: $inaktive
          ) {
            id
            active
            lon
            lat
          }
        }
      `,
      variables: {
        tage: tage,
        inaktive: inaktive
      },
      //fetchPolicy: cache ? 'cache-first' : 'no-cache',
    });
  }

  findSchadenWithFilter(filter: any, cache: boolean = false): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findSchadenWithFilter($filter: SchadenFilter!) {
          findSchadenWithFilter(filter: $filter) {
            id
            datum
            createdAt
            userCreated
            modifiedAt
            userModified
            arbeitsauftrageVorhanden
            active
            lon
            lat
            prioritaet {
              id
              name
              color
            }
            teilanlage {
              id
              name
            }
            schadenart {
              id
              name
            }
            strassenabschnitt {
              id
              bezeichnung
            }
            zubegehenStrassenabschnitt {
              id
              bezeichnung
            }
            arbeitsauftrage {
              id
              erledigt
              nummer
              team {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        filter: filter
      },
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
      errorPolicy: 'all',
    });
  }

  reset(): void {
    this._begehungId = null;
    this._strassenabschnitt = null;
    this._zubegehenStrassenabschnittId = null;
    this._prioritaetId = null;
    this._teilanlageId = null;
    this._schadenartId = null;
    this._schadenId = null;

    this.datum = new Date();

    this.lon = null;
    this.lat = null;

    this.laenge = null;
    this.breite = null;
    this.tiefe = null;

    this._bemerkung = null;

    this.fotos = new Set<string>();
  }

  get strassenabschnitt(): any {
    return this._strassenabschnitt;
  }
  set strassenabschnitt(o: any) {
    this._strassenabschnitt = o;
  }

  get zubegehenStrassenabschnittId(): string|unknown {
    return this._zubegehenStrassenabschnittId;
  }
  set zubegehenStrassenabschnittId(id: string|unknown) {
    this._zubegehenStrassenabschnittId = id;
  }

  get prioritaetId(): string|unknown {
    return this._prioritaetId;
  }
  set prioritaetId(id: string|unknown) {
    this._prioritaetId = id;
  }

  get teilanlageId(): string|unknown {
    return this._teilanlageId;
  }
  set teilanlageId(id: string|unknown) {
    this._teilanlageId = id;
  }

  get schadenId(): string|unknown {
    return this._schadenId;
  }
  set schadenId(id: string|unknown) {
    this._schadenId = id;
  }

  get schadenartId(): string|unknown {
    return this._schadenartId;
  }
  set schadenartId(id: string|unknown) {
    this._schadenartId = id;
  }

  get begehungId(): string|unknown {
    return this._begehungId;
  }
  set begehungId(begehungId: string|unknown) {
    this._begehungId = begehungId;
  }

  get bemerkung(): string|unknown {
    return this._bemerkung;
  }
  set bemerkung(bemerkung: string|unknown) {
    this._bemerkung = bemerkung;
  }

  get backlink(): any {
    return this._backlink;
  }
  set backlink(link: any) {
    this._backlink = link;
  }
}
