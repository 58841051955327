import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QueryRef } from 'apollo-angular';
import { WebcamImage } from 'ngx-webcam';
import { map, take, tap } from 'rxjs';
import { TakePhotoComponent } from 'src/app/foto/take-photo/take-photo.component';
import { FotoService } from 'src/app/services/foto.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { SchadenService } from 'src/app/services/schaden.service';

@UntilDestroy()
@Component({
  selector: 'app-list-fotos-on-schaden',
  templateUrl: './list-fotos-on-schaden.component.html',
  styleUrls: ['./list-fotos-on-schaden.component.scss']
})
export class ListFotosOnSchadenComponent implements OnInit, OnDestroy {

  schadenId: string|null;
  fotos: any;
  findFotoQueryRef?: QueryRef<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fotoService: FotoService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private progressBarService: ProgressBarService,
    private schadenService: SchadenService) {
      this.schadenId = this.activatedRoute.snapshot.paramMap.get("schadenId");
  }

  ngOnInit(): void {
    if(this.schadenId) {
      console.log("schadenId:", this.schadenId);
      this.progressBarService.add();
      this.findFotoQueryRef = this.fotoService.findAllFotosBySchadenId(this.schadenId);
      this.fotos = this.findFotoQueryRef.valueChanges.pipe(untilDestroyed(this), map(result => result.data.findSchadenById.fotos), tap(() => this.progressBarService.remove()));
    }
  }

  ngOnDestroy(): void {
    
  }

  openTakePhoto(schadenId: string|null): void {
    console.log("")
    if(schadenId) {
      const dialogRef = this.dialog.open(TakePhotoComponent);
      dialogRef.afterClosed().subscribe((result: WebcamImage) => {
        this.schadenService.addFoto(result.imageAsDataUrl, schadenId).pipe(take(1)).subscribe({
          next: () => {
            this.snackBar.open("Foto gespeichert", "", {duration: 1000});
            this.findFotoQueryRef?.refetch();
          },
          error: error => {
            this.snackBar.open("Fehler beim Speichern des Fotos: "+ error, "OK");
          }
        })
      });
    }
  }
}
