import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-fullscreen-photo',
  templateUrl: './fullscreen-photo.component.html',
  styleUrls: ['./fullscreen-photo.component.scss']
})
export class FullscreenPhotoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {foto: any},
    private dialogRef: MatDialogRef<FullscreenPhotoComponent>,
    ) { 
    console.log("Dialog data:", this.data);
  }

  close(): void {
    this.dialogRef.close();
  }
}
