import { Component, Input } from '@angular/core';
import { KarteModule } from 'src/app/karte/karte.module';
import { ReparaturSchadenLayerComponent } from '../reparatur-schaden-layer/reparatur-schaden-layer.component';

@Component({
  selector: 'app-reparatur-karte',
  standalone: true,
  imports: [
    KarteModule,
    ReparaturSchadenLayerComponent,
  ],
  templateUrl: './reparatur-karte.component.html',
  styleUrl: './reparatur-karte.component.scss'
})
export class ReparaturKarteComponent {
}
