import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QueryRef } from 'apollo-angular';
import { Observable, Subscription, map, take } from 'rxjs';
import { BasisKarteComponent } from 'src/app/karte/basis-karte/basis-karte.component';
import { ConfigService } from 'src/app/services/config.service';
import { PrioritaetService } from 'src/app/services/prioritaet.service';
import { SchadenartService } from 'src/app/services/schadenart.service';
import { TeamService } from 'src/app/services/team.service';
import { TeilanlageService } from 'src/app/services/teilanlage.service';

@UntilDestroy()
@Component({
  selector: 'app-team-karte-schaden',
  templateUrl: './team-karte-schaden.component.html',
  styleUrl: './team-karte-schaden.component.scss'
})
export class TeamKarteSchadenComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("karte")
  _map!: BasisKarteComponent;

  _teamId!: string;
  team: any;

  _teilanlagen!: Observable<any>;
  _schadenarten!: Observable<any>;
  _prioritaeten!: Observable<any>;


  prioritaetId: any;
  teilanlageId: any;
  schadenartId: any;

  @Input({required: true})
  set teamId(teamId: string) {
    this._teamId = teamId;

    this.team = this.teamService.findTeamById(teamId).valueChanges.pipe(take(1), map(r => r.data.findTeamById));
  }

  constructor(
    private configService: ConfigService,
    private teamService: TeamService,
    private teilanlageService: TeilanlageService,
    private schadenartService: SchadenartService,
    private prioritaetService: PrioritaetService,
  ) {
  }

  ngOnInit(): void {
    this._teilanlagen = this.teilanlageService.findAllTeilanlagen().valueChanges.pipe(take(1), map((r) => r.data.findAllTeilanlagen))
    this._schadenarten = this.schadenartService.findAllSchadenarten().valueChanges.pipe(take(1), map((r) => r.data.findAllSchadenarten));
    this._prioritaeten = this.prioritaetService.findAllPrioritaeten().valueChanges.pipe(take(1), map((r) => r.data.findAllPrioritaeten));
  }

  ngAfterViewInit(): void {
    this._map.getView().setCenter(this.configService.map.center);
    this._map.getView().setZoom(this.configService.map.zoom);
  }

  ngOnDestroy(): void {
  }
}
