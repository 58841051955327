import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SchadenStateService } from '../services/schaden-state.service';
import { TeilanlageService } from '../services/teilanlage.service';
import { PrioritaetService } from '../services/prioritaet.service';
import { SchadenartService } from '../services/schadenart.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { KarteModule } from '../karte/karte.module';
import { KarteNeuerSchadenComponent } from './schaden/karte-neuer-schaden/karte-neuer-schaden.component';
import { KarteNeuerSchadenBegehungComponent } from './begehung/karte-neuer-schaden-begehung/karte-neuer-schaden-begehung.component';
import { FotoModule } from '../foto/foto.module';
import { ListFotosOnSchadenComponent } from './list-fotos-on-schaden/list-fotos-on-schaden.component';
import { ListSchadenComponent } from './list-schaden/list-schaden.component';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { SchadenInfoDialogComponent } from './schaden-info-dialog/schaden-info-dialog.component';
import { UploadPhotoComponent } from '../foto/upload-photo/upload-photo.component';

@NgModule({
  declarations: [
    CreateDialogComponent,
    KarteNeuerSchadenComponent,
    KarteNeuerSchadenBegehungComponent,
    ListFotosOnSchadenComponent,
    ListSchadenComponent,
    SchadenInfoDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    MatDialogModule,
    MatButtonModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatIconModule,
    KarteModule,
    FotoModule,
    UploadPhotoComponent,
  ],
  exports: [
    KarteNeuerSchadenComponent,
    KarteNeuerSchadenBegehungComponent,
  ],
  providers: [
    SchadenStateService,
    TeilanlageService,
    PrioritaetService,
    SchadenartService,
  ]
})
export class SchaedenModule { }
