import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-question-dialog',
  templateUrl: './question-dialog.component.html',
  styleUrls: ['./question-dialog.component.scss']
})
export class QuestionDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<QuestionDialogComponent>,
    @Inject(DIALOG_DATA) public data: {title: string, message: string}
    ) {
    }

  clickYes(): void {
    this.dialogRef.close({
      result: true
    });
  }

  clickNo(): void {
    this.dialogRef.close({
      result: false
    });
  }
}
