import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BezirkAuswahlComponent } from './bezirk-auswahl/bezirk-auswahl.component';
import { StrassenInBezirkComponent } from './strassen-in-bezirk/strassen-in-bezirk.component';
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    BezirkAuswahlComponent,
    StrassenInBezirkComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  exports: [
    BezirkAuswahlComponent,
    StrassenInBezirkComponent,
  ]
})
export class BezirkModule { }
