import { Component, OnDestroy, OnInit, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-upload-photo',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
  ],
  templateUrl: './upload-photo.component.html',
  styleUrl: './upload-photo.component.scss'
})
export class UploadPhotoComponent implements OnInit, OnDestroy {

  uploadedImage = output<string>();

  constructor() {

  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  selectFile(event: any): void {
    console.log("Event: ", event);

    const selectedFiles = event.target.files;
    if(selectedFiles) {
      const file: File | null = selectedFiles[0];

      if(file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          console.log("E:", e);
          //const image = e.target.result;
          this.uploadedImage.emit(e.target.result);
        }
        reader.readAsDataURL(file);
      }
    }
  }
}
