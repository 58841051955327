import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BasisKarteComponent } from '../basis-karte/basis-karte.component';
import { SelectedFeatureEvent } from 'src/app/types/selected-feature-event';
import { Collection, Feature } from 'ol';
import { tap } from 'rxjs';
import { QueryRef } from 'apollo-angular';
import { Stroke, Style } from 'ol/style';
import { Select } from 'ol/interaction';
import WKT from 'ol/format/WKT';
import { ZubegehenStrassenabschnittService } from 'src/app/services/zubegehen-strassenabschnitt.service';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-select-zubegehen-strassenabschnitt',
  templateUrl: './select-zubegehen-strassenabschnitt.component.html',
  styleUrls: ['./select-zubegehen-strassenabschnitt.component.scss']
})
export class SelectZubegehenStrassenabschnittComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input()
  karte!: BasisKarteComponent;

  @Input()
  begehungId!: string;

  @Input()
  tolerance: number = 12;

  @Input()
  zIndex: number = 5;

  @Output()
  seletcedFeature: EventEmitter<SelectedFeatureEvent> = new EventEmitter();

  _layerAbschnitte: any;
  _sourceAbschnitte: any;
  _features: Collection<Feature> = new Collection();

  _refZubegehenStrassenabschnitte: QueryRef<any>|undefined;

  _styleActive!: Style;
  _styleInactive!: Style;

  _selectFeature!: Select;

  _format = new WKT();

  constructor(private zubegehenStrassenabschnittService: ZubegehenStrassenabschnittService,
    private progressBarService: ProgressBarService) {}

  ngOnInit(): void {
    console.debug("SelectZubegehenAbschnitt");
    console.debug("BegehungId:", this.begehungId);

    this.initStyles();
  }

  ngAfterViewInit(): void {
    this.initLayer();

    this.initInteraction();

    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.debug("Changes:", changes);
    this.loadData();
  }

  ngOnDestroy(): void {
    this.karte.getMap().removeLayer(this._layerAbschnitte);
    this.karte.getMap().removeInteraction(this._selectFeature);
    console.debug("Destroy SelectZubegehenAbschnitt");
  }

  private initLayer(): void {
    this._sourceAbschnitte = new VectorSource({
      features: this._features,
    });
    this._layerAbschnitte = new VectorLayer({
      source: this._sourceAbschnitte,
      title: "Abschnitte",
      style: this._styleActive,
      zIndex: this.zIndex,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    } as any);

    this.karte.getMap().addLayer(this._layerAbschnitte);
  }

  private initStyles(): void {
    this._styleActive = new Style({
      stroke: new Stroke({
        color: "#ffff00",
        width: 5,
      }),
    });
    this._styleInactive = new Style({
      stroke: new Stroke({
        color: "#00ff00",
        width: 2,
      }),
    });
  }

  private initInteraction(): void {
    this._selectFeature = new Select({
      layers: [this._layerAbschnitte],
      hitTolerance: this.tolerance,
      filter: (feature) => {
        if(feature.get("erledigtAt")) {
          return false;
        }
        return true;
      }
    });

    this._selectFeature.on("select", evt => {
      if(evt.selected.length>0 && evt.selected[0].get("erledigtAt")==null) {
        const myEvent: SelectedFeatureEvent = {
          feature: evt.selected[0],
          coordinate: evt.mapBrowserEvent.coordinate,
        };
        this.seletcedFeature.emit(myEvent);
      } else {
        this.seletcedFeature.emit(undefined);
      }
      
    });

    this.karte.getMap().addInteraction(this._selectFeature);
  }

  private loadData(): void {
    this._refZubegehenStrassenabschnitte = this.zubegehenStrassenabschnittService.findZubegehenStrassenabschnittGeomByBegehungId(this.begehungId)
    this.progressBarService.add();
    this._refZubegehenStrassenabschnitte.valueChanges.pipe(untilDestroyed(this), tap(() => this.progressBarService.remove())).subscribe(result => {
      this._features.clear();
      console.debug("LOAD ZSA Features");

      for(const source of result.data.findZubegehenStrassenabschnittByBegehungId) {
        this._features.push(this.makeFeature(source));
      }
    });
  }

  private makeFeature(source: any): Feature {
    const geometry = this._format.readGeometry(source.geometry);
    const feature = new Feature(geometry.transform("EPSG:4326", "EPSG:3857"));
    feature.setId(source.id);
    if(source.erledigtAt) {
      feature.setStyle(this._styleInactive);
    }
    feature.setProperties({
      type: "sa",
      bezeichnung: source.bezeichnung,
      erledigtAt: source.erledigtAt,
    });
    return feature;
  }

  public refetch(): void {
    this._refZubegehenStrassenabschnitte?.refetch();
  }

  public enableInteraction(): void {
    this.karte.getMap().addInteraction(this._selectFeature);
  }
  public disableInteraction(): void {
    this.karte.getMap().removeInteraction(this._selectFeature);
  }

  public enableLayer(): void {
    this.karte.getMap().addLayer(this._layerAbschnitte);
  }
  public disableLayer(): void {
    this.karte.getMap().removeLayer(this._layerAbschnitte);
  }
}
