import { Injectable } from '@angular/core';
import { QueryRef } from '@apollo/client';
import { Apollo, MutationResult, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FotoArbeitService {

  constructor(private apollo: Apollo) { }

  public addFotoArbeit(arbeitsauftragId: string, foto: string): Observable<MutationResult> {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation addFotoArbeit(
          $arbeitsauftragId: ID!,
          $foto: String
        ) {
          addFotoArbeit(
            arbeitsauftragId: $arbeitsauftragId,
            foto: $foto
          ) {
            id
          }
        }
      `,
      variables: {
        arbeitsauftragId: arbeitsauftragId,
        foto: foto,
      }
    });
  }
}
