import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, take, tap } from 'rxjs';
import { BegehungService } from 'src/app/services/begehung.service';
import { BezirkService } from 'src/app/services/bezirk.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';

@UntilDestroy()
@Component({
  selector: 'app-neue-begehung',
  templateUrl: './neue-begehung.component.html',
  styleUrls: ['./neue-begehung.component.scss']
})
export class NeueBegehungComponent implements OnInit, OnDestroy {
  bezirke: any;

  form = new FormGroup({
    bezirk: new FormControl("", Validators.required),
  });

  constructor(
    private router: Router,
    private progressBarService: ProgressBarService,
    private bezirkService: BezirkService,
    private snackBar: MatSnackBar,
    private begehungService: BegehungService) {}



  ngOnInit(): void {
    this.progressBarService.clear();
    this.progressBarService.add();
    this.bezirke = this.bezirkService.findAllBezirke().valueChanges.pipe(untilDestroyed(this), map(result => result.data.findAllBezirke), tap(() => this.progressBarService.remove()));
  }

  ngOnDestroy(): void {
    
  }

  public onSubmit(form: FormGroup): void {
    if(this.form.valid) {
      const datepipe = new DatePipe("en-US");
      const newDate: string|null = datepipe.transform(new Date(), "yyyy-MM-dd");
      if(this.form.value.bezirk && newDate) {
        this.progressBarService.add();
        this.begehungService.createBegehung(this.form.value.bezirk, newDate).pipe(take(1), tap(() => this.progressBarService.remove())).subscribe({
          next: result => {
            this.router.navigate(["/"]);
          },
          error: error => {
            this.snackBar.open("Fehler beim erstellen einer Begehung: "+ error, "OK");
          }
        });
      }
    }
  }
}
