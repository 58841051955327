import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SecurityService } from './services/security.service';

export const roleGuard: CanActivateFn = (route, state) => {
  const securityService = inject(SecurityService);
  //console.log("Route / State:", route, state, securityService);
  if(route.data["role"]) {
    return securityService.hasRole(route.data["role"]);
  }
  return true;
};
