import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QueryRef } from 'apollo-angular';
import { Collection, Feature } from 'ol';
import { Geometry, Point } from 'ol/geom';
import { Select } from 'ol/interaction';
import { Vector } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { BasisKarteComponent } from 'src/app/karte/basis-karte/basis-karte.component';
import { KarteModule } from 'src/app/karte/karte.module';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { StateTeamService } from 'src/app/services/state-team.service';
import { TeamService } from 'src/app/services/team.service';
import { ReparaturSchadenDialogComponent } from '../reparatur-schaden-dialog/reparatur-schaden-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-reparatur-schaden-layer',
  standalone: true,
  imports: [
    KarteModule,
    MatSnackBarModule,
    MatDialogModule,
  ],
  templateUrl: './reparatur-schaden-layer.component.html',
  styleUrl: './reparatur-schaden-layer.component.scss'
})
export class ReparaturSchadenLayerComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  karte!: BasisKarteComponent;

  @Input()
  tolerance: number = 12;

  @Input()
  zIndex: number = 10;

  _teamQueryRef!: QueryRef<any>;
  team: any;

  _teamVector: Collection<Feature<Geometry>> = new Collection();
  _teamSource!: VectorSource;
  _teamLayer!: Vector<VectorSource>;
  _teamSelect!: Select;

  constructor(
    private progressBarService: ProgressBarService,
    private stateTeamService: StateTeamService,
    private teamService: TeamService,
    private snackbarService: MatSnackBar,
    private dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
    this.progressBarService.clear();
  }

  ngAfterViewInit(): void {
    this.loadData();

    this.addLayers();
  }

  ngOnDestroy(): void {
    this.karte.getMap().removeInteraction(this._teamSelect);
    this.karte.getMap().removeLayer(this._teamLayer);
  }

  private addLayers(): void {
    this._teamSource = new VectorSource({
      features: this._teamVector,
    });

    this._teamLayer = new Vector({
      source: this._teamSource,
      zIndex: this.zIndex,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    this.karte.getMap().addLayer(this._teamLayer);

    this._teamSelect = new Select({
      layers: [this._teamLayer],
      hitTolerance: this.tolerance,
    });

    this.karte.getMap().addInteraction(this._teamSelect);

    this._teamSelect.on("select", (evt) => {
      if(evt.selected.length>0) {
        console.log("select:", evt.selected[0].get("data"));
        const dialogRef = this.dialog.open(ReparaturSchadenDialogComponent, {
          data: {
            arbeitsauftrag: evt.selected[0].get("data"),
          }
        });
        dialogRef.afterClosed().subscribe((r) => {
          this._teamQueryRef.refetch();
        });
      }
    });
  }

  private loadData(): void {
    if(this.stateTeamService.id) {
      this._teamQueryRef = this.teamService.findTeamByIdWithSchaeden(this.stateTeamService.id);
      this.progressBarService.add();
      this._teamQueryRef.valueChanges.pipe(untilDestroyed(this)).subscribe({
        next: (r) => {
          this.progressBarService.remove();
          this.team = r.data.findTeamById;
          console.log("Team:", this.team);

          this.addLayerData();
        },
        error: (err) => {
          this.snackbarService.open("Fehler beim Laden: "+ err);
        }
      });
    }
  }

  private addLayerData(): void {
    this._teamVector.clear();
    const proj = this.karte.getView().getProjection();
    this.team.arbeitsauftrage.forEach((element: any) => {
      console.log(element);

      if(!element.erledigt) {
        const geom = new Point(fromLonLat([element.schaden.lon, element.schaden.lat], proj));

        const style = new Style({
          image: new CircleStyle({
            radius: 9,
            fill: new Fill({
              color: "rgba(0, 0, 255, 1.0)",
            }),
            stroke: new Stroke({
              color: element.schaden.prioritaet.color,
              width: 3,
            }),
          }),
        });

        const feature = new Feature({
          geometry: geom,
          data: element,
        });
        feature.setStyle(style);
        feature.setId(element.schaden.id);

        this._teamVector.push(feature);
      }
    });
  }
}
