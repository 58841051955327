import { Injectable } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class TeilanlageService {

  constructor(private apollo: Apollo) { }

  findAllTeilanlagen(): QueryRef<any> {
    return this.apollo.watchQuery({
      query: gql`
      query findAllTeilanlagen {
        findAllTeilanlagen {
          id
          name
        }
      }
      `
    });
  }
}
