<div class="mid">
    <webcam
        class="camera"
        (imageCapture)="imageCapture($event)"
        (imageClick)="imageClicked()"
        [trigger]="triggerCapture()"
        [width]="640"
        [videoOptions]="videoOptions">
    </webcam>
</div>
