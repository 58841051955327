import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SchadenStateService {

  public strassenabschnittId: string|undefined|null;
  public begehungId: string|undefined|null;
  public zubegehenStrassenabschnitId: string|undefined|null;
  public prioritaetId: string|undefined|null;
  public teilanlageId: string|undefined|null;
  public schadenartId: string|undefined|null;
  public schadenId: string|undefined|null;

  public laenge: number|undefined|null;
  public breite: number|undefined|null;
  public tiefe: number|undefined|null;
  public datum: Date|undefined|null;
  public lon: number|undefined|null;
  public lat: number|undefined|null;

  public bemerkung: string|undefined;

  private backlink: any;

  public fotos!: string[];

  constructor() { }

  public reset(): void {
    this.strassenabschnittId = undefined;
    this.begehungId = undefined;
    this.zubegehenStrassenabschnitId = undefined;
    this.prioritaetId = undefined;
    this.teilanlageId = undefined;
    this.schadenartId = undefined;
    this.schadenId = undefined;

    this.laenge = undefined;
    this.breite = undefined;
    this.tiefe = undefined;
    this.datum = undefined;
    this.lon = undefined;
    this.lat = undefined;

    this.bemerkung = undefined;

    this.backlink = undefined;

    this.fotos = [];
  }

  public serialize(): string {
    return JSON.stringify(this);
  }

  public deserialize(s: string): void {
    JSON.parse(s);
    Object.assign(this, JSON.parse(s));
  }

  public storeSession(): void {
    sessionStorage.setItem("schadenStateService", this.serialize());
  }
  public restoreSession(): void {
    const o = sessionStorage.getItem("schadenStateService");
    if(o) {
      Object.assign(this, o);
    }
  }
  public clearSession(): void {
    sessionStorage.removeItem("schadenStateService");
  }
}
