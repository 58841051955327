<h1 mat-dialog-title>Neuer Schaden</h1>
<div mat-dialog-content>
    <form [formGroup]="_teamForm">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput type="text" formControlName="name">
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="buttons">
    <div class="right-buttons">
        <button mat-button color="warn" (click)="onCancel()">Abbruch</button>
        <button mat-button color="accent" (click)="onOkay()" [disabled]="!_teamForm.valid">Speichern</button>
    </div>
</div>
