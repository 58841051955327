import { Component } from '@angular/core';
import { VersionInfoService } from 'src/app/services/version-info.service';

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent {
  version: any;
  constructor(public versionInfoService: VersionInfoService) {

  }
}
