import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { AbschnittMenuComponent } from 'src/app/karte/abschnitt-menu/abschnitt-menu.component';
import { CreateSchadenComponent } from 'src/app/karte/create-schaden/create-schaden.component';
import { GpsTrackingComponent } from 'src/app/karte/gps-tracking/gps-tracking.component';
import { SchaedenLayerComponent } from 'src/app/karte/schaeden-layer/schaeden-layer.component';
import { SchadenStateService } from 'src/app/services/schaden-state.service';
import { SchadenService } from 'src/app/services/schaden.service';
import { SelectedFeatureEvent } from 'src/app/types/selected-feature-event';
import { CreateDialogComponent } from '../../create-dialog/create-dialog.component';
import { toLonLat } from 'ol/proj';
import { SelectZubegehenStrassenabschnittComponent } from 'src/app/karte/select-zubegehen-strassenabschnitt/select-zubegehen-strassenabschnitt.component';
import { ZubegehenStrassenabschnittService } from 'src/app/services/zubegehen-strassenabschnitt.service';
import { ActivatedRoute } from '@angular/router';
import { QuestionDialogComponent } from 'src/app/helper/question-dialog/question-dialog.component';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-karte-neuer-schaden-begehung',
  templateUrl: './karte-neuer-schaden-begehung.component.html',
  styleUrls: ['./karte-neuer-schaden-begehung.component.scss']
})
export class KarteNeuerSchadenBegehungComponent implements OnInit, OnDestroy {

  @ViewChild("selectZubegehenAbschnitt")
  selectZubegehenAbschnittComponent!: SelectZubegehenStrassenabschnittComponent;
  @ViewChild("abschnittMenu")
  abschnittMenuComponent!: AbschnittMenuComponent;
  @ViewChild("gps")
  _gps!: GpsTrackingComponent;
  @ViewChild("schadenLayer")
  _schadenLayer!: SchaedenLayerComponent;
  @ViewChild("createSchaden")
  _createSchadenComponent!: CreateSchadenComponent;

  _begehungId!: string;

  _zubegehenStrassenabschnitt: Feature|undefined;



  constructor(private schadenStateService: SchadenStateService,
    private schadenService: SchadenService,
    private progressBarService: ProgressBarService,
    private snackBar: MatSnackBar,
    private zubegehenStrassenabschnittService: ZubegehenStrassenabschnittService,
    private dialog: MatDialog,
    private route: ActivatedRoute) {

    let begehungId = this.route.snapshot.paramMap.get("begehungId");
    if(begehungId) this._begehungId = begehungId;
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  newSchadenPoint(e: SelectedFeatureEvent): void {
    console.debug("newSchaden:", e);
    this.schadenStateService.reset();
    this.schadenStateService.clearSession();
    this._zubegehenStrassenabschnitt = e.feature;
    const geo = this._gps.getGeolocation();
    const c = geo.getPosition();
    if(c) {
      const f = new Feature({
        geometry: new Point(c),
        projection: geo.getProjection(),
      });

      this.selectZubegehenAbschnittComponent.disableInteraction();
      this.abschnittMenuComponent.closePopup();

      this._createSchadenComponent.addSchadenAt(f);
      this._createSchadenComponent.enableControl();
      this._createSchadenComponent.enableLayer();
      this._createSchadenComponent.enableInteraction();
    }
  }

  closeAbschnitt(e: SelectedFeatureEvent): void {
    console.debug("closeAbschnitt:", e);
    if(e.feature.getId()) {
      const dialogRef = this.dialog.open(QuestionDialogComponent, {
        data: {
          title: "Abschnitt schließen",
          message: "Soll der Abschnitt "+ e.feature.get("bezeichnung"),
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.result===true) {
          this.zubegehenStrassenabschnittService.closeZubegehenStrassenabschnitt(e.feature.getId() as string).pipe(take(1)).subscribe({
            next: result => {
              this.snackBar.open("Abschnitt abgeschlossen", "", {duration: 1000});
              this.selectZubegehenAbschnittComponent.refetch();
              this.abschnittMenuComponent.closePopup();
            },
            error: error => {
              this.snackBar.open("Fehler beim abschliessen des Abschnittes", "OK");
            }
          });
        }
      });
    }
  }

  createSchadenAt(e: Feature): void {
    console.debug("Commited Schaden:", e);
    console.debug("At ZubegehenStrassenabschnitt:", this._zubegehenStrassenabschnitt);

    this.schadenStateService.zubegehenStrassenabschnitId = this._zubegehenStrassenabschnitt?.getId() as string;

    const point = e.getGeometry() as Point;
    const lonLat = toLonLat(point.getCoordinates());
    this.schadenStateService.lon = lonLat[0];
    this.schadenStateService.lat = lonLat[1];

    this._createSchadenComponent.disableInteraction();
    this._createSchadenComponent.disableLayer();
    this._createSchadenComponent.disableControl();

    this.dialog.open(CreateDialogComponent, {
      hasBackdrop: false,
    }).afterClosed().subscribe(result => {
      console.debug("Dialog closed", result);
      if(result === "submit") {
        this.saveSchaden();
      }

      this.selectZubegehenAbschnittComponent.enableInteraction();
    });
  }

  cancelSchaden(): void {
    console.debug("Cancel add schaden");

    this._createSchadenComponent.disableInteraction();
    this._createSchadenComponent.disableLayer();
    this._createSchadenComponent.disableControl();

    this.selectZubegehenAbschnittComponent.enableInteraction();
  }

  saveSchaden(): void {
    this.progressBarService.add();
    this.schadenService.createNewWithZubegehenStrassenabschnitt(
      this.schadenStateService.zubegehenStrassenabschnitId as string,
      this.schadenStateService.prioritaetId as string,
      this.schadenStateService.teilanlageId as string,
      this.schadenStateService.schadenartId as string,
      new Date(),
      this.schadenStateService.lon as number,
      this.schadenStateService.lat as number,
      this.schadenStateService.laenge as number,
      this.schadenStateService.breite as number,
      this.schadenStateService.tiefe as number,
      this.schadenStateService.bemerkung as string
    ).pipe(take(1)).subscribe({
      next: result => {
        this.progressBarService.remove();
        console.log("Saved schaden:", result);
        const schadenId = result.data.createSchaden.id;

        this.snackBar.open("Schaden gespeichert", "", {
          duration: 1000,
        });
        this._schadenLayer.refetch();

        for(const foto of this.schadenStateService.fotos) {
          this.progressBarService.add();
          this.schadenService.addFoto(foto, schadenId).pipe(take(1)).subscribe({
            next: result => {
              this.progressBarService.remove();
              console.log("Add Foto:", result);
              this.snackBar.open("Foto gespeichert", "", {
                duration: 1000,
              });
            },
            error: error => {
              this.progressBarService.remove();
              this.snackBar.open("Fehler beim speichern des Fotos:"+ error, "OK");
            }
          });
        }
      },
      error: error => {
        this.progressBarService.remove();
        this.snackBar.open("Fehler beim speichern des Schaden:"+ error, "Ok");
      }
    });
  }
}
