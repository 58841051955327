<mat-nav-list>
    <mat-list-item routerLink="/schaden/neu"><mat-icon>add_alert</mat-icon>Neuer Schaden</mat-list-item>
    <mat-expansion-panel>
        <mat-expansion-panel-header>Allgemeines</mat-expansion-panel-header>
        <mat-nav-list>
            <mat-list-item routerLink="/schaden"><mat-icon>checklist</mat-icon>Liste Schäden</mat-list-item>
            <mat-list-item routerLink="/bezirke/strassen"><mat-icon>streetview</mat-icon>Liste Bezirke</mat-list-item>
        </mat-nav-list>
    </mat-expansion-panel>

    @if(securityService.isBegeher()) {
        <mat-expansion-panel>
            <mat-expansion-panel-header>Begeher</mat-expansion-panel-header>
            <mat-nav-list>
                <mat-list-item routerLink="/begehung/neu/1"><mat-icon>checklist</mat-icon>Neue Begehung</mat-list-item>
            </mat-nav-list>
        </mat-expansion-panel>
    }

    @if(securityService.isMeister()) {
        <mat-expansion-panel>
            <mat-expansion-panel-header>Meister</mat-expansion-panel-header>
            <mat-nav-list>
                <mat-list-item routerLink="/teams/list"><mat-icon>group_add</mat-icon>Team</mat-list-item>
            </mat-nav-list>
        </mat-expansion-panel>
    }

    @if(securityService.isStrassenwaerter()) {
        <mat-expansion-panel>
            <mat-expansion-panel-header>Straßenwärter</mat-expansion-panel-header>
            <mat-nav-list>
                <mat-list-item routerLink="/reparatur"><mat-icon>group</mat-icon>Team wahl</mat-list-item>
                <mat-list-item routerLink="/reparatur/schaeden"><mat-icon>list</mat-icon>Schadenliste</mat-list-item>
            </mat-nav-list>
        </mat-expansion-panel>
    }
    <mat-list-item (click)="clearApolloCache()"><mat-icon>cached</mat-icon>Cache löschen</mat-list-item>
    <mat-list-item (click)="clearMSAL()"><mat-icon>logout</mat-icon>Abmelden</mat-list-item>
</mat-nav-list>
