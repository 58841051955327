import { Injectable } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private apollo: Apollo) { }

  public findAllTeams(cache: boolean = false): QueryRef<any> {
    return this.apollo.watchQuery({
      query: gql`
        query findAllTeams {
          findAllTeams {
            id
            name
          }
        }
      `,
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    });
  }

  public findTeamById(id: string, cache: boolean = false): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findTeamById($id: ID!) {
          findTeamById(id: $id) {
            userCreated
            createdAt
            userModified
            modifiedAt
            active
            
            id
            name
          }
        }
      `,
      variables: {
        id: id
      },
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    });
  }

  public findTeamByIdWithSchaeden(id: string, cache: boolean = false): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findTeamByIdWithSchaeden($id: ID!) {
          findTeamById(id: $id) {
            userCreated
            createdAt
            userModified
            modifiedAt
            active
            
            id
            name

            arbeitsauftrage {
              id
              active
              erledigt
              nummer
              beschreibung

              schaden {
                id
                datum
                lon
                lat
                bemerkung

                prioritaet {
                  id
                  name
                  color
                }
                teilanlage {
                  id
                  name
                }
                schadenart {
                  id
                  name
                }
                strassenabschnitt {
                  id
                  bezeichnung
                }
                zubegehenStrassenabschnitt {
                  id
                  bezeichnung
                }
              }
            }
          }
        }
      `,
      variables: {
        id: id
      },
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    });
  }
/*
  public findTeamByIdWidthSchaden(id: string, cache: boolean = false): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findTeamById($id: ID!) {
          findTeamById(id: $id) {
            userCreated
            createdAt
            userModified
            modifiedAt
            active
            
            id
            name

            arbeitsauftrage {
              id
              active
              erledigt
              nummer
              beschreibung

              schaden {
                id
                active

                strassenabschnitt {
                  id
                  bezeichnung
                }

                zubegehenStrassenabschnitt {
                  id
                  bezeichnung
                }

                prioritaet {
                  id
                  name
                  color
                }

                teilanlage {
                  id
                  name
                }

                schadenart {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        id: id
      },
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    });
  }
  */
  public findTeamByName(name: string, cache: boolean = false): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findTeamByName($name: String!) {
          findTeamByName(name: $name) {
            userCreated
            createdAt
            userModified
            modifiedAt
            active
            
            id
            name
          }
        }
      `,
      variables: {
        name: name
      },
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    });
  }

  public createTeam(name: string) {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation createTeam(
          $name: String!
        ) {
          createTeam(
            name: $name
          ) {
            id
          }
        }
      `,
      variables: {
        name: name,
      }
    });
  }

  public deleteTeam(id: string) {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation deleteTeam(
          $id: ID!
        ) {
          deleteTeam(
            id: $id
          ) {
            id,
            active
          }
        }
      `,
      variables: {
        id: id,
      }
    });
  }
}
