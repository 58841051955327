import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { SecurityRights } from '../types/security-rights';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private authService: MsalService) {}

  public isBegeher(): boolean {
    return this.hasRole(SecurityRights.Begeher);
  }

  public isMeister(): boolean {
    return this.hasRole(SecurityRights.Meister);
  }

  public isStrassenwaerter(): boolean {
    return this.hasRole(SecurityRights.Strassenwaerter);
  }

  public hasRole(role: string): boolean {
    if(this.authService.instance.getAllAccounts().length > 0) {
      const account = this.authService.instance.getAllAccounts()[0];
      if(account.idTokenClaims?.roles) {
        return account.idTokenClaims.roles.includes(role);
      }
    }
    return false;
  }
}
