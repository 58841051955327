import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListZubegehnStrassenabschnitteComponent } from './list-zubegehn-strassenabschnitte/list-zubegehn-strassenabschnitte.component';
import { ListeBegehungenComponent } from './liste-begehungen/liste-begehungen.component';
import { NeueBegehungComponent } from './neue-begehung/neue-begehung.component';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    ListZubegehnStrassenabschnitteComponent,
    ListeBegehungenComponent,
    NeueBegehungComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
  ],
  exports: [
    ListZubegehnStrassenabschnitteComponent,
    ListeBegehungenComponent,
    NeueBegehungComponent,
  ]
})
export class BegehungModule { }
