<div class="filter-outer">
    <mat-expansion-panel class="filter">
        <mat-expansion-panel-header>Filter</mat-expansion-panel-header>
            <mat-form-field>
                <mat-label>Priorität</mat-label>
                <mat-select [(ngModel)]="prioritaetId">
                    <mat-option>-</mat-option>
                    @for(prioritaet of (_prioritaeten | async); track prioritaet.id) {
                        <mat-option [value]="prioritaet.id">{{prioritaet.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Teilanlage</mat-label>
                <mat-select [(ngModel)]="teilanlageId" multiple>
                    @for(teilanlage of (_teilanlagen | async); track teilanlage.id) {
                        <mat-option [value]="teilanlage.id">{{teilanlage.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Schadenart</mat-label>
                <mat-select [(ngModel)]="schadenartId" multiple>
                    @for(schadenart of (_schadenarten | async); track schadenart.id) {
                        <mat-option [value]="schadenart.id">{{schadenart.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
    </mat-expansion-panel>
</div>
<app-basis-karte #karte></app-basis-karte>
<app-osm-layer [karte]="karte"></app-osm-layer>
<app-luftbilder-layer [karte]="karte"></app-luftbilder-layer>
<app-team-layer-schaeden [karte]="karte" [team]="team | async" [filterPrioritaet]="prioritaetId" [filterTeilanlage]="teilanlageId" [filterSchadenart]="schadenartId"></app-team-layer-schaeden>
