import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { BackendVersionInfoService } from 'src/app/services/backend-version-info.service';
import { BackendVersionInfo } from 'src/app/types/backend-version-info';

@Component({
  selector: 'app-backend-version-info',
  templateUrl: './backend-version-info.component.html',
  styleUrls: ['./backend-version-info.component.scss'],
})
export class BackendVersionInfoComponent implements OnInit {

  backendVersionInfo!: Observable<BackendVersionInfo>;

  constructor(private backendVersionInfoService: BackendVersionInfoService, public dialogRef: MatDialogRef<BackendVersionInfoComponent>) {}

  ngOnInit(): void {
    this.backendVersionInfo = this.backendVersionInfoService.getVersion().valueChanges.pipe(map(r => r.data.getVersion));
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
