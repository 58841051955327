import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BasisKarteComponent } from '../basis-karte/basis-karte.component';
import { StrassenabschnittService } from 'src/app/services/strassenabschnitt.service';
import { Collection, Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Subscription, tap } from 'rxjs';
import WKT from 'ol/format/WKT';
import { Stroke, Style } from 'ol/style';
import { Select } from 'ol/interaction';
import { QueryRef } from 'apollo-angular';
import { SelectedFeatureEvent } from 'src/app/types/selected-feature-event';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-select-abschnitt',
  templateUrl: './select-abschnitt.component.html',
  styleUrls: ['./select-abschnitt.component.scss']
})
export class SelectAbschnittComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  karte!: BasisKarteComponent;

  @Input()
  tolerance: number = 12;

  @Input()
  zIndex: number = 5;

  @Output()
  seletcedFeatureCallback: EventEmitter<SelectedFeatureEvent> = new EventEmitter();

  _layerAbschnitte: any;
  _sourceAbschnitte: any;
  _features: Collection<Feature> = new Collection();

  _subStrassenabschnitte: Subscription|undefined;
  _refStrassenabschnitte: QueryRef<any>|undefined;

  _styleNormal!: Style;

  _selectFeature!: Select;

  _format = new WKT();

  constructor(private strassenabschnittService: StrassenabschnittService,
    private progressBarService: ProgressBarService,
  ) {}

  ngOnInit(): void {
    this.initStyles();
  }

  ngAfterViewInit(): void {
    this.initLayer();

    this.initInteraction();

    this.loadData();
  }

  ngOnDestroy(): void {
    this.karte.getMap().removeLayer(this._layerAbschnitte);
    this.disableInteraction();
    console.debug("Destroy SelectAbschnitt");
  }

  private initLayer(): void {
    this._sourceAbschnitte = new VectorSource({
      features: this._features,
    });
    this._layerAbschnitte = new VectorLayer({
      source: this._sourceAbschnitte,
      title: "Abschnitte",
      style: this._styleNormal,
      zIndex: this.zIndex,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    } as any);

    this.karte.getMap().addLayer(this._layerAbschnitte);
  }

  private initStyles(): void {
    this._styleNormal = new Style({
      stroke: new Stroke({
        color: "#0000ff",
        width: 5,
      }),
    });
  }

  private initInteraction(): void {
    this._selectFeature = new Select({
      layers: [this._layerAbschnitte],
      hitTolerance: this.tolerance,
    });

    this._selectFeature.on("select", evt => {
      if(evt.selected.length>0) {
        const myEvent: SelectedFeatureEvent = {
          feature: evt.selected[0],
          coordinate: evt.mapBrowserEvent.coordinate,
        };
        this.seletcedFeatureCallback.emit(myEvent);
      } else {
        this.seletcedFeatureCallback.emit(undefined);
      }
    });

    this.enableInteraction();
  }

  private loadData(): void {
    this._refStrassenabschnitte = this.strassenabschnittService.findAllStrassenabschnitteWithGeom();
    this.progressBarService.add();
    this._subStrassenabschnitte = this._refStrassenabschnitte.valueChanges.pipe(untilDestroyed(this), tap(() => this.progressBarService.remove())).subscribe(result => {
      this._features.clear();
      console.debug("LOAD  SA Features");

      for(const source of result.data.findAllStrassenabschnitte) {
        this._features.push(this.makeFeature(source));
      }
    });
  }

  private makeFeature(source: any): Feature {
    const geometry = this._format.readGeometry(source.geometry);
    const feature = new Feature(geometry.transform("EPSG:4326", "EPSG:3857"));
    feature.setId(source.id);
    feature.setProperties({
      type: "sa",
      bezeichnung: source.bezeichnung,
      erledigtAt: source.erledigtAt,
    });
    return feature;
  }

  public reload(): void {
    this._refStrassenabschnitte?.refetch();
  }

  public enableInteraction(): void {
    this.karte.getMap().addInteraction(this._selectFeature);
  }
  public disableInteraction(): void {
    this.karte.getMap().removeInteraction(this._selectFeature);
  }

  public enableLayer(): void {
    this.karte.getMap().addLayer(this._layerAbschnitte);
  }
  public disableLayer(): void {
    this.karte.getMap().removeLayer(this._layerAbschnitte);
  }
}
