import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackendVersionInfoComponent } from './backend-version-info/backend-version-info.component';
import { VersionInfoComponent } from './version-info/version-info.component';



@NgModule({
  declarations: [
    BackendVersionInfoComponent,
    VersionInfoComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BackendVersionInfoComponent,
    VersionInfoComponent,
  ]
})
export class VersionModule { }
