{
  "dirty": false,
  "raw": "1.1.12-1-g5516893",
  "hash": "g5516893",
  "distance": 1,
  "tag": "1.1.12",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "1.1.12",
    "major": 1,
    "minor": 1,
    "patch": 12,
    "prerelease": [],
    "build": [],
    "version": "1.1.12"
  },
  "suffix": "1-g5516893",
  "semverString": "1.1.12+1.g5516893",
  "buildDate": "2024-07-02T06:59:14.332Z"
}