<h1 mat-dialog-title>Neuer Schaden</h1>

<div mat-dialog-content>
    <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>Fotos</mat-expansion-panel-header>
        @for (f of (schaden | async)?.fotos; track f.id) {
            <img class="photo" src="{{f.foto}}">
        }
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>Informationen</mat-expansion-panel-header>
        <mat-form-field class="prioritaet">
            <mat-label>Priorität</mat-label>
            <input matInput readonly="true" value="{{(schaden | async)?.prioritaet.name}}">
        </mat-form-field>

        <br/>

        <mat-form-field class="strasse">
            <mat-label>Straßenabschnitt</mat-label>
            <input matInput readonly="true" value="{{getStrassenabschnitt(schaden | async)}}">
        </mat-form-field>

        <br/>

        <mat-form-field class="teilanlage">
            <mat-label>Teilanlage</mat-label>
            <input matInput readonly="true" value="{{(schaden | async)?.teilanlage.name}}">
        </mat-form-field>
        <mat-form-field class="schadenart">
            <mat-label>Schadenart</mat-label>
            <input matInput readonly="true" value="{{(schaden | async)?.schadenart.name}}">
        </mat-form-field>

    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>Aufnahmedaten</mat-expansion-panel-header>
        <mat-form-field class="user">
            <mat-label>Erstellt von</mat-label>
            <input matInput readonly="true" value="{{(schaden | async)?.userCreated}}">
        </mat-form-field>
        <mat-form-field class="date">
            <mat-label>Erstellt am</mat-label>
            <input matInput readonly="true" value="{{(schaden | async)?.createdAt | date: 'dd.MM.yyyy HH:mm:ss'}}">
        </mat-form-field>

        <br/>

        <mat-form-field class="user">
            <mat-label>Geändert von</mat-label>
            <input matInput readonly="true" value="{{(schaden | async)?.userModified}}">
        </mat-form-field>
        <mat-form-field class="date">
            <mat-label>Geändert am</mat-label>
            <input matInput readonly="true" value="{{(schaden | async)?.modifiedAt | date: 'dd.MM.yyyy HH:mm:ss'}}">
        </mat-form-field>
    </mat-expansion-panel>
</div>

<div mat-dialog-actions class="buttons">
    <div class="right-buttons">
        <button mat-button color="primary" (click)="dialog.close()">Schließen</button>
    </div>
</div>
