import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, map, of, take, tap } from 'rxjs';
import { BegehungService } from 'src/app/services/begehung.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';

@UntilDestroy()
@Component({
  selector: 'app-liste-begehungen',
  templateUrl: './liste-begehungen.component.html',
  styleUrls: ['./liste-begehungen.component.scss']
})
export class ListeBegehungenComponent implements OnInit, OnDestroy {

  begehungen: any;
  displayedColumns = [
    "datum",
    "name",
    "userCreated",
    "tools",
  ];

  constructor(
    private snackBar: MatSnackBar,
    private progressBarService: ProgressBarService,
    private begehungService: BegehungService,
  ) {
  }

  ngOnInit(): void {
    this.progressBarService.clear();
    this.progressBarService.add();
    this.begehungen = this.begehungService.findAllBegehungen().valueChanges.pipe(
      untilDestroyed(this),
      map(result => result.data.findAllBegehungen),
      tap(() => this.progressBarService.remove()),
      catchError(err => {
        this.progressBarService.remove();
        this.snackBar.open("Fehler beim Laden der Begehungen: "+ err, "OK");
        return of();
      }),
    );
  }

  ngOnDestroy(): void {
    
  }
}
