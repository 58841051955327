import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-team-create-dialog',
  templateUrl: './team-create-dialog.component.html',
  styleUrl: './team-create-dialog.component.scss'
})
export class TeamCreateDialogComponent {

  _teamForm = new FormGroup({
    name: new FormControl("", Validators.required),
  })

  constructor(
    private dialogRef: MatDialogRef<TeamCreateDialogComponent>,
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onOkay(): void {
    this.dialogRef.close(this._teamForm.value);
  }
}
