import { Injectable } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class PrioritaetService {

  constructor(private apollo: Apollo) { }

  findAllPrioritaeten(): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
      query findAllPrioritaeten {
        findAllPrioritaeten {
          id
          name
        }
      }
      `,
      errorPolicy: 'none'
    });
  }
}
