<h1>Begehung</h1>
<table mat-table [dataSource]="skList" class="mar-elevation-z8 sticky-table color-table">
    <ng-container matColumnDef="bezeichnung">
        <th mat-header-cell *matHeaderCellDef>Bezeichnung</th>
        <td mat-cell *matCellDef="let sk ">{{sk.bezeichnung}}</td>
    </ng-container>

    <ng-container matColumnDef="erledigtAt">
        <th mat-header-cell *matHeaderCellDef>Erledigt am</th>
        <td mat-cell *matCellDef="let sk">{{sk.erledigtAt | date:'dd.MM.yyyy HH:mm'}}</td>
    </ng-container>

    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let sk">
            <button mat-mini-fab color="primary" [disabled]="sk.erledigtAt" [routerLink]="['/schaden/neu/gps', begehungId, sk.id]" matTooltip="auf Karte"><mat-icon>add_location</mat-icon></button>
            <button class="left-space" mat-mini-fab color="warn" [disabled]="sk.erledigtAt" (click)="closeZubegehen(sk)" matTooltip="auf Karte"><mat-icon>close</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
