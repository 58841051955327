<h1 mat-dialog-title>Neuer Arbeitsauftrag</h1>
<div mat-dialog-content>
    <form [formGroup]="_arbeitsauftragForm">
        <mat-form-field>
            <mat-label>Team</mat-label>
            <mat-select formControlName="teamId">
                @for (team of (_teams | async); track team) {
                    <mat-option [value]="team.id">{{team.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Beschreibung</mat-label>
            <textarea matInput formControlName="beschreibung"></textarea>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="buttons">
    <div class="right-buttons">
        <button mat-button color="warn" (click)="onCancel()">Abbruch</button>
        <button mat-button color="accent" (click)="onOkay()" [disabled]="!_arbeitsauftragForm.valid">Speichern</button>
    </div>
</div>
