<h1>Bezirkübersicht</h1>
<form [formGroup]="form">
  <div class="form">
    <mat-form-field>
      <mat-label>Bezirk</mat-label>
      <mat-select formControlName="bezirk">
        @for (b of bezirke | async; track b) {
          <mat-option [value]="b.id">{{b.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</form>
<div>
  <app-strassen-in-bezirk [bezirkId]="form.controls['bezirk'].value"></app-strassen-in-bezirk>
</div>
