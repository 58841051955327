<h1 mat-dialog-title>Neuer Schaden</h1>
<div mat-dialog-content>
  <mat-stepper orientation="vertical" #stepper>
    <mat-step [stepControl]="_basicForm">
      <ng-template matStepLabel>Basisdaten</ng-template>
      <ng-template matStepContent>

        <form [formGroup]="_basicForm">
          <mat-form-field class="space-right">
            <mat-label>Priorität</mat-label>
            <mat-select formControlName="prioritaet">
              @for (p of $any(_prioritaeten | async) ; track p.id) {
                <mat-option [value]="p.id">{{p.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field class="space-right">
            <mat-label>Teilanlage</mat-label>
            <mat-select formControlName="teilanlage">
              @for (p of $any(_teilanlagen | async) ; track p.id) {
                <mat-option [value]="p.id">{{p.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field class="space-right">
            <mat-label>Schadenart</mat-label>
            <mat-select formControlName="schadenart">
              @for (p of $any(_schadensarten | async) ; track p.id) {
                <mat-option [value]="p.id">{{p.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </form>
      </ng-template>
    </mat-step>



    <mat-step [stepControl]="_extraForm" optional="">
      <ng-template matStepLabel>Zusatzinfo</ng-template>
      <ng-template matStepContent>

        <form [formGroup]="_extraForm">
          <div class="form">
            <mat-form-field class="space-right">
              <mat-label>Länge</mat-label>
              <input matInput type="number" formControlName="laenge">
            </mat-form-field>

            <mat-form-field class="space-right">
              <mat-label>Breite</mat-label>
              <input matInput type="number" formControlName="breite">
            </mat-form-field>

            <mat-form-field class="space-right">
              <mat-label>Tiefe</mat-label>
              <input matInput type="number" formControlName="tiefe">
            </mat-form-field>

            <mat-form-field class="space-right">
              <mat-label>Bemerkung</mat-label>
              <textarea matInput cdkTextareaAutosize formControlName="bemerkung" placeholder="ggf. weiter Bemerkungen für den Schaden"></textarea>
            </mat-form-field>
          </div>
        </form>
      </ng-template>
    </mat-step>



    <mat-step [completed]="_fotosValid">
      <ng-template matStepLabel>Fotos</ng-template>
      <ng-template matStepContent>
        <div>
          <button mat-flat-button color="primary" (click)="openTakePhoto()">Foto erstellen</button>
          <app-upload-photo class="upload-button" (uploadedImage)="uploadPhoto($event)"></app-upload-photo>
        </div>
        <div>
          @for (img of schadenStateService.fotos; track img) {
            <img [src]="img" width="200px" class="photo">
          }
        </div>
      </ng-template>
    </mat-step>
  </mat-stepper>
</div>
<div mat-dialog-actions class="buttons">
  <div class="left-buttons">
    <button mat-button color="primary" (click)="stepper.previous()">Zurück</button>
    <button mat-button color="primary" (click)="stepper.next()">Weiter</button>
  </div>
  <div class="right-buttons">
    <button mat-button color="warn" (click)="onCancel()">Abbruch</button>
    <button mat-button color="accent" (click)="onOkay()" [disabled]="!formsValid()">Speichern</button>
  </div>
</div>
