<h1>Neue Begehung</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit(form)">
  <div class="form">
    <div class="sticky-form-buttons">
      <button mat-flat-button type="submit" color="primary" [disabled]="form.invalid">Erstellen</button>
    </div>

    <mat-form-field>
      <mat-label>Bezirk</mat-label>
      <mat-select formControlName="bezirk">
        @for (b of $any(bezirke | async) ; track b.id) {
          <mat-option [value]="b.id">{{b.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</form>
