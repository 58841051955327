import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QueryRef } from 'apollo-angular';
import { Subject, Subscription, tap } from 'rxjs';
import { BezirkService } from 'src/app/services/bezirk.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';

@UntilDestroy()
@Component({
  selector: 'app-strassen-in-bezirk',
  templateUrl: './strassen-in-bezirk.component.html',
  styleUrls: ['./strassen-in-bezirk.component.scss']
})
export class StrassenInBezirkComponent implements OnDestroy, OnChanges {

  @Input()
  bezirkId!: string|null;

  displayedColumns = ["name"];

  strassenabschnitte: Subject<any> = new Subject();
  strassenabschnitteQueryRef!: QueryRef<any, {id: string}>;

  constructor(private bezirkService: BezirkService,
    private progressBarService: ProgressBarService
  ) {}

  ngOnDestroy(): void {
    this.strassenabschnitte?.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["bezirkId"].currentValue) {
      if(!this.strassenabschnitteQueryRef) {
        this.strassenabschnitteQueryRef = this.bezirkService.findBezirkById(changes["bezirkId"].currentValue);
        this.progressBarService.add();
        this.strassenabschnitteQueryRef.valueChanges.pipe(untilDestroyed(this), tap(() => this.progressBarService.remove())).subscribe(r => {
          const sa = new Set();
          for(const d of r.data.findBezirkById.strassenabschnitte) {
            sa.add(d.strasse.name);
          }
          this.strassenabschnitte.next(Array.from(sa));
        });
      } else {
        this.progressBarService.add();
        this.strassenabschnitteQueryRef.setVariables({
          id: changes["bezirkId"].currentValue
        });
      }
    }
  }
}
