import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, WritableSignal, computed, model, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map, tap } from 'rxjs';
import { FotoService } from 'src/app/services/foto.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { SchadenService } from 'src/app/services/schaden.service';
import { FotoModule } from 'src/app/foto/foto.module';
import { TakePhotoComponent } from 'src/app/foto/take-photo/take-photo.component';
import { WebcamImage } from 'ngx-webcam';
import { FotoArbeitService } from 'src/app/services/foto-arbeit.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ArbeitsauftragService } from 'src/app/services/arbeitsauftrag.service';
import { UploadPhotoComponent } from 'src/app/foto/upload-photo/upload-photo.component';

@UntilDestroy()
@Component({
  selector: 'app-reparatur-schaden-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatCardModule,
    MatSnackBarModule,
    FotoModule,
    UploadPhotoComponent,
  ],
  templateUrl: './reparatur-schaden-dialog.component.html',
  styleUrl: './reparatur-schaden-dialog.component.scss'
})
export class ReparaturSchadenDialogComponent implements OnInit, OnDestroy {

  _fotos!: Observable<any>;

  _takenFotos: WritableSignal<any[]> = signal([]);
  saveEnabled = computed(() => {
    console.log("Len:", this._takenFotos().length);
    return this._takenFotos().length>0 && this._erledigt();
  });
  _erledigt = model(false);
  _bemerkung!: string;

  _arbeitsauftrag: any;
  _schaden!: Observable<any>;

  constructor(
    private progressbarService: ProgressBarService,
    public dialogRef: MatDialogRef<ReparaturSchadenDialogComponent>,
    private fotoService: FotoService,
    private schadenService: SchadenService,
    private fotoArbeitService: FotoArbeitService,
    private arbeitauftragService: ArbeitsauftragService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)public data: any,
  ) {
    this._arbeitsauftrag = data.arbeitsauftrag;
  }

  ngOnInit(): void {
    this.progressbarService.clear();
    console.log("Data:", this._arbeitsauftrag);
    this.progressbarService.add();
    this._fotos = this.fotoService.findAllFotosBySchadenId(this._arbeitsauftrag.schaden.id).valueChanges.pipe(untilDestroyed(this), map(r => r.data.findSchadenById), tap(() => this.progressbarService.remove()));

    this.progressbarService.add();
    this._schaden = this.schadenService.findSchadenById(this._arbeitsauftrag.schaden.id).valueChanges.pipe(untilDestroyed(this), map(r => r.data.findSchadenById), tap(() => this.progressbarService.remove()));
  }

  ngOnDestroy(): void {
  }

  getStrassenabschnitt(schaden: any): string {
    return schaden?.strassenabschnitt?.bezeichnung ? schaden?.strassenabschnitt?.bezeichnung : schaden?.zubegehenStrassenabschnitt?.bezeichnung;
  }

  openTakePhoto(): void {
    const dialogRef = this.dialog.open(TakePhotoComponent, {

    });
    dialogRef.afterClosed().subscribe((event: WebcamImage) => {
      this._takenFotos.update((arr: any[]) => {
        arr.push(event.imageAsDataUrl);
        return arr.slice(0);
      });
    });
  }

  uploadPhoto(event: any): void {
    this._takenFotos.update((arr: any[]) => {
      arr.push(event);
      return arr.slice(0);
    });
  }

  save(): void {
    this.arbeitauftragService.arbeitsauftragErledigt(this._arbeitsauftrag.id, this._bemerkung).subscribe({
      next: (result) => {
        this.snackbar.open("Auftrag als erledigt gekennzeichnet");

        this.dialogRef.close(true);
        this._takenFotos().forEach((foto) => {
          this.fotoArbeitService.addFotoArbeit(this._arbeitsauftrag.id, foto).subscribe({
            next: (result) => {
              this.snackbar.open("Bild gespeichert", "", {duration: 1000});
            },
            error: (err) => {
              this.snackbar.open("Fehler beim Foto speichern: "+ err, "OK");
            }
          });
        });
      }, error: (err) => {
        this.snackbar.open("Fehler beim Auftragbeenden: "+ err, "OK");
      }
    });
  }
}
