import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-schaden-dialog',
  templateUrl: './delete-schaden-dialog.component.html',
  styleUrls: ['./delete-schaden-dialog.component.scss']
})
export class DeleteSchadenDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteSchadenDialogComponent>,
    @Inject(DIALOG_DATA) public data: {
      id: string,
      datum: Date,
      prioritaet: string,
      teilanlage: string,
      strassenabschnitt: string,
      userCreated: Date,
      createdAt: Date,
    }
  ) {}

  clickYes(): void {
    this.dialogRef.close({
      result: true
    });
  }

  clickNo(): void {
    this.dialogRef.close({
      result: false
    });
  }
}
