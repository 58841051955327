<h1>Schäden</h1>
<table mat-table [dataSource]="schaeden" class="mat-elevation-z8 sticky-table color-table">
    <ng-container matColumnDef="datum">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let schaden">{{schaden.datum | date: 'dd.MM.yyyy HH:mm' }}</td>
    </ng-container>

    <ng-container matColumnDef="prioritaet">
        <th mat-header-cell *matHeaderCellDef>Priorität</th>
        <td mat-cell *matCellDef="let schaden">{{schaden.prioritaet.name}}</td>
    </ng-container>

    <ng-container matColumnDef="teilanlage">
        <th mat-header-cell *matHeaderCellDef>Teilanlage</th>
        <td mat-cell *matCellDef="let schaden">{{schaden.teilanlage.name}}</td>
    </ng-container>

    <ng-container matColumnDef="schadenart">
        <th mat-header-cell *matHeaderCellDef>Schadenart</th>
        <td mat-cell *matCellDef="let schaden">{{schaden.schadenart.name}}</td>
    </ng-container>

    <ng-container matColumnDef="strassenabschnitt">
        <th mat-header-cell *matHeaderCellDef>Straßenabschnitt</th>
        <td mat-cell *matCellDef="let schaden">{{getStrassenabschnittBezeichnung(schaden)}}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Erstellt am</th>
        <td mat-cell *matCellDef="let schaden">{{schaden.createdAt | date: 'dd.MM.yyyy HH:mm'}}</td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef>Erstellt von</th>
        <td mat-cell *matCellDef="let schaden">{{schaden.userCreated}}</td>
    </ng-container>

    <ng-container matColumnDef="arbeitsauftrageVorhanden">
        <th mat-header-cell *matHeaderCellDef class="center">Arbeitsauftrag</th>
        <td mat-cell *matCellDef="let schaden" class="center">
            @if(securityService.isMeister()) {
                @if(schaden.arbeitsauftrageVorhanden) {
                    <button mat-mini-fab color="warn" (click)="deleteArbeitsauftrag(schaden)"><mat-icon>check_circle</mat-icon></button>
                } @else {
                    <button mat-mini-fab color="primary" (click)="createArbeitsauftrag(schaden)"><mat-icon>cancel</mat-icon></button>
                }
            } @else {
                @if(schaden.arbeitsauftrageVorhanden) {
                    <mat-icon>check_circle</mat-icon>
                } @else {
                    <mat-icon>cancel</mat-icon>
                }
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let schaden">
            <button mat-mini-fab color="primary" [routerLink]="['/schaden/fotos', schaden.id]"><mat-icon>photo</mat-icon></button>
            @if(securityService.isMeister()) {
                <button mat-mini-fab color="warn" class="left-space" (click)="deleteSchaden(schaden)"><mat-icon>delete</mat-icon></button>
            }
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
