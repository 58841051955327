import { AfterViewInit, Component, Input } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { BasisKarteComponent } from '../basis-karte/basis-karte.component';

import { ImageWMS, TileWMS as TileWMSSource, } from 'ol/source';
import { WebGLTile as WebGLTileLayer, Image as ImageLayer } from 'ol/layer';

@Component({
  selector: 'app-luftbilder-layer',
  templateUrl: './luftbilder-layer.component.html',
  styleUrls: ['./luftbilder-layer.component.scss']
})
export class LuftbilderLayerComponent implements AfterViewInit {

  urlDopOverlay = "https://www.wms.nrw.de/geobasis/wms_nw_dop_overlay";

  @Input()
  karte!: BasisKarteComponent;

  @Input()
  visible: boolean = true;

  @Input()
  zIndex: number = 0;

  _layerLuftbilder!: WebGLTileLayer;
  _sourceLuftbilder!: TileWMSSource;

  _layerOverlay!: ImageLayer<ImageWMS>;
  _sourceOverlay!: ImageWMS;

  constructor(private configService: ConfigService) {}

  ngAfterViewInit(): void {
    this.initEuvLuftbilder();
    this.initGeobasisNrwOverlay();
  }

  initEuvLuftbilder(): void {
    console.debug("WMS LB config:", this.configService);
    if(this.configService.geoserverWmsUrls?.length>0) {
      console.debug("Use multi site for LB");
      this._sourceLuftbilder = new TileWMSSource({
        urls: this.configService.geoserverWmsUrls,
        params: {
          LAYERS: "luftbilder:Luftbilder",
          FORMAT: "image/jpeg",
          TILED: "true",
        },
        serverType: "geoserver",
        attributions: "EUV Stadtbetrieb Castrop-Rauxel -AöR-",
      });
    } else {
      console.debug("Use single site for LB");
      this._sourceLuftbilder = new TileWMSSource({
        url: this.configService.geoserverWmsUrl,
        params: {
          LAYERS: "luftbilder:Luftbilder",
          FORMAT: "image/jpeg",
          TILED: "true",
        },
        serverType: "geoserver",
        attributions: "EUV Stadtbetrieb Castrop-Rauxel -AöR-",
      });
    }

    this._layerLuftbilder = new WebGLTileLayer({
      title: 'Luftbilder',
      type: 'base',
      preload: 2,
      visible: this.visible,
      zIndex: this.zIndex,
      source: this._sourceLuftbilder,
    } as any);

    this.karte.getMap().addLayer(this._layerLuftbilder);
  }

  initGeobasisNrwOverlay(): void {
    /* Geobasis NRW DOP overlay */
    this._sourceOverlay = new ImageWMS({
      url: this.urlDopOverlay,
      params: {
        "layers": "nw_dop_overlay_hausnummern,,nw_dop_overlay_bundesstrassen_beschriftung_2,nw_dop_overlay_landstrassen_beschriftung,nw_dop_overlay_kreisstrassen_beschriftung,nw_dop_overlay_ortsstrassen_beschriftung,nw_dop_overlay_unbefahrbare_wege",
      },
      attributions: "Geobasis NRW",
    });

     this._layerOverlay = new ImageLayer({
      title: "Straßen",
      source: this._sourceOverlay,
      zIndex: this.zIndex + 1,
    } as any);

    this.karte.getMap().addLayer(this._layerOverlay);
  }

  public getLayer(): WebGLTileLayer {
    return this._layerLuftbilder;
  }
  public getSource(): TileWMSSource {
    return this._sourceLuftbilder;
  }

  public getOverlayLayer(): ImageLayer<ImageWMS> {
    return this._layerOverlay;
  }
  public getOverlaySourcer(): ImageWMS {
    return this._sourceOverlay;
  }
}
