import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  public display: Signal<boolean> = computed(() => {
    return this._counter() > 0;
  });
  _counter: WritableSignal<number> = signal(0);

  constructor() {}

  public add(): void {
    this._counter.update(v => v+1);
  }

  public remove(): void {
    this._counter.update(v => {
      if(v>0) {
        v = v-1;
      }
      return v;
    });
  }

  public clear(): void {
    this._counter.update(v => 0);
  }
}
