import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QueryRef } from 'apollo-angular';
import { Observable, map, tap } from 'rxjs';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { TeamService } from 'src/app/services/team.service';

@UntilDestroy()
@Component({
  selector: 'app-create-arbeitsauftrag-dialog',
  templateUrl: './create-arbeitsauftrag-dialog.component.html',
  styleUrl: './create-arbeitsauftrag-dialog.component.scss'
})
export class CreateArbeitsauftragDialogComponent implements OnInit, OnDestroy {

  _teamQueryRef: QueryRef<any>;
  _teams!: Observable<any>;

  _arbeitsauftragForm = new FormGroup({
    teamId: new FormControl(null, Validators.required),
    beschreibung: new FormControl(),
  });


  constructor(
    private teamService: TeamService,
    private progressBarService: ProgressBarService,
    private dialogRef: MatDialogRef<CreateArbeitsauftragDialogComponent>,
  ) {
    this._teamQueryRef = this.teamService.findAllTeams();
  }


  ngOnInit(): void {
    this.progressBarService.add();
    this._teams = this._teamQueryRef.valueChanges.pipe(untilDestroyed(this), map(result => result.data.findAllTeams), tap(() => this.progressBarService.remove()));
  }

  ngOnDestroy(): void {
    
  }

  onOkay(): void {
    this.dialogRef.close(this._arbeitsauftragForm.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
