import { Injectable } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class BackendVersionInfoService {

  constructor(private apollo: Apollo) { }

  getVersion(): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query getVersion {
          getVersion {
            artifact
            group
            name
            time
            version
          }
        }
      `,
    });
  }
}
