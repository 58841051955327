import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map, tap } from 'rxjs';
import { BezirkService } from 'src/app/services/bezirk.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';

@UntilDestroy()
@Component({
  selector: 'app-bezirk-auswahl',
  templateUrl: './bezirk-auswahl.component.html',
  styleUrls: ['./bezirk-auswahl.component.scss']
})
export class BezirkAuswahlComponent implements OnInit, OnDestroy {

  form = new FormGroup<any>({
    bezirk: new FormControl(),
  });

  bezirke!: Observable<any>;

  constructor(private bezrikService: BezirkService,
    private progressBarService: ProgressBarService,
  ) {
  }

  ngOnInit(): void {
    this.progressBarService.clear();
    this.progressBarService.add();
    this.bezirke = this.bezrikService.findAllBezirke().valueChanges.pipe(untilDestroyed(this), map(result => result.data.findAllBezirke), tap(() => this.progressBarService.remove()));
  }

  ngOnDestroy(): void {
    
  }

  onSubmit(form: FormGroup<any>): void {

  }
}
