import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { LoginFailedComponent } from './login-failed/login-failed.component';
import { HomeComponent } from './home/home.component';
import { QuestionDialogComponent } from './helper/question-dialog/question-dialog.component';
import { BackendVersionInfoService } from './services/backend-version-info.service';

import { SchadenStateService } from './services/schaden-state.service';
import { SchadenartService } from './services/schadenart.service';
import { BegehungService } from './services/begehung.service';
import { ConfigService } from './services/config.service';
import { ProgressBarService } from './services/progress-bar.service';
import { TeilanlageService } from './services/teilanlage.service';
import { BezirkService } from './services/bezirk.service';
import { FotoService } from './services/foto.service';
import { PrioritaetService } from './services/prioritaet.service';
import { SchadenService } from './services/schaden.service';
import { StrassenabschnittService } from './services/strassenabschnitt.service';
import { ZubegehenStrassenabschnittService } from './services/zubegehen-strassenabschnitt.service';

import { KarteModule } from './karte/karte.module';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion'
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SchaedenModule } from './schaeden/schaeden.module';
import { DeleteSchadenDialogComponent } from './helper/delete-schaden-dialog/delete-schaden-dialog.component';
import { FotoModule } from './foto/foto.module';
import { VersionModule } from './version/version.module';
import { BegehungModule } from './begehung/begehung.module';
import { BezirkModule } from './bezirk/bezirk.module';
import { MeisterModule } from './meister/meister.module';
import { SidenavService } from './services/sidenav.service';
import { SideMenuComponent } from './home/side-menu/side-menu.component';
import { WakeLockService } from './services/wake-lock.service';
import { ReparaturListComponent } from './reparatur/reparatur-list/reparatur-list.component';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function initConfig(configService: ConfigService) {
  return () => configService.load();
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log("LOG[", logLevel, "]:", message);
}

export function MSALInstanceFactory(configService: ConfigService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: configService.clientId,
      authority: configService.authority,
      redirectUri: "/auth",
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(configService: ConfigService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(configService.api_url, [configService.api_id+"/user"]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(configService: ConfigService): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [configService.api_id+"/user"]
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
    declarations: [
      AppComponent,
      LoginFailedComponent,
      HomeComponent,
      SideMenuComponent,
      QuestionDialogComponent,
      DeleteSchadenDialogComponent,
    ],
    bootstrap: [
      AppComponent,
      MsalRedirectComponent
    ],
    imports: [
      ReparaturListComponent,
      FotoModule,
      KarteModule,
      VersionModule,
      BegehungModule,
      BezirkModule,
      MeisterModule,
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      GraphQLModule,
      MatMomentDateModule,
      ReactiveFormsModule,
      MatSidenavModule,
      MatToolbarModule,
      MatIconModule,
      MatButtonModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatSelectModule,
      MatInputModule,
      MatAutocompleteModule,
      MatMenuModule,
      MatListModule,
      MatTooltipModule,
      MatDialogModule,
      MatSnackBarModule,
      MatTableModule,
      MatProgressSpinnerModule,
      MatCheckboxModule,
      MatDatepickerModule,
      SchaedenModule,
      MsalModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
      })
    ],
    providers: [
        SchadenStateService,
        BegehungService,
        BezirkService,
        ConfigService,
        FotoService,
        PrioritaetService,
        SchadenService,
        SchadenartService,
        StrassenabschnittService,
        TeilanlageService,
        ZubegehenStrassenabschnittService,
        ProgressBarService,
        BackendVersionInfoService,
        SidenavService,
        WakeLockService,
        {
          provide: ErrorHandler,
          useClass: ApplicationinsightsAngularpluginErrorService,
        },
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useValue: {
                dateFormat: 'dd.MM.yyyy',
                timezone: 'Europe/Berlin'
            }
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-DE',
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {
                useUtc: false
            }
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [ConfigService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
            deps: [ConfigService]
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
            deps: [ConfigService]
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
            deps: [ConfigService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
