import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { LoginFailedComponent } from './login-failed/login-failed.component';
import { ListSchadenComponent } from './schaeden/list-schaden/list-schaden.component';
import { HomeComponent } from './home/home.component';
import { NeueBegehungComponent } from './begehung/neue-begehung/neue-begehung.component';
import { ListZubegehnStrassenabschnitteComponent } from './begehung/list-zubegehn-strassenabschnitte/list-zubegehn-strassenabschnitte.component';
import { BezirkAuswahlComponent } from './bezirk/bezirk-auswahl/bezirk-auswahl.component';
import { KarteNeuerSchadenComponent } from './schaeden/schaden/karte-neuer-schaden/karte-neuer-schaden.component';
import { KarteNeuerSchadenBegehungComponent } from './schaeden/begehung/karte-neuer-schaden-begehung/karte-neuer-schaden-begehung.component';
import { ListFotosOnSchadenComponent } from './schaeden/list-fotos-on-schaden/list-fotos-on-schaden.component';
import { TeamListComponent } from './meister/teams/team-list/team-list.component';
import { TeamKarteSchadenComponent } from './meister/teams/team-karte-schaden/team-karte-schaden.component';
import { TeamSchadenListComponent } from './meister/teams/team-schaden-list/team-schaden-list.component';
import { roleGuard } from './role.guard';
import { SecurityRights } from './types/security-rights';
import { ReparaturTeamWahlComponent } from './reparatur/reparatur-team-wahl/reparatur-team-wahl.component';
import { teamGuard } from './team.guard';
import { ReparaturListComponent } from './reparatur/reparatur-list/reparatur-list.component';
import { ReparaturKarteComponent } from './reparatur/reparatur-karte/reparatur-karte.component';

const routes: Routes = [
  {
    path: "schaden/neu",
    component: KarteNeuerSchadenComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "schaden/fotos/:schadenId",
    component: ListFotosOnSchadenComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "schaden",
    component: ListSchadenComponent,
    canActivate: [MsalGuard],
  },

  {
    path: "begehung/offen/:begehungId",
    component: ListZubegehnStrassenabschnitteComponent,
    canActivate: [MsalGuard, roleGuard],
    data: { role: SecurityRights.Begeher },
  },
  {
    path: "begehung/offen/karte/:begehungId",
    component: KarteNeuerSchadenBegehungComponent,
    canActivate: [MsalGuard, roleGuard],
    data: { role: SecurityRights.Begeher },
  },
  {
    path: "begehung/neu/1",
    component: NeueBegehungComponent,
    canActivate: [MsalGuard, roleGuard],
    data: { role: SecurityRights.Begeher },
  },

  {
    path: "teams/list",
    component: TeamListComponent,
    canActivate: [MsalGuard, roleGuard],
    data: { role: SecurityRights.Meister },
  },
  {
    path: "teams/:teamId/schaden/karte",
    component: TeamKarteSchadenComponent,
    canActivate: [MsalGuard, roleGuard],
    data: { role: SecurityRights.Meister },
  },
  {
    path: "teams/:teamId/schaden/list",
    component: TeamSchadenListComponent,
    canActivate: [MsalGuard, roleGuard],
    data: { role: SecurityRights.Meister },
  },

  {
    path: "bezirke/strassen",
    component: BezirkAuswahlComponent,
    canActivate: [MsalGuard],
  },

  {
    path: "reparatur",
    component: ReparaturTeamWahlComponent,
    canActivate: [MsalGuard, roleGuard],
    data: { role: SecurityRights.Strassenwaerter },
  },
  {
    path: "reparatur/schaeden",
    component: ReparaturListComponent,
    canActivate: [MsalGuard, roleGuard, teamGuard],
    data: { role: SecurityRights.Strassenwaerter},
  },
  {
    path: "reparatur/schaeden/karte",
    component: ReparaturKarteComponent,
    canActivate: [MsalGuard, roleGuard, teamGuard],
    data: { role: SecurityRights.Strassenwaerter},
  },

  {
    path: "login-failed",
    component: LoginFailedComponent,
  },
  {
    path: "auth",
    component: MsalRedirectComponent,
  },
  {
    path: "",
    component: HomeComponent,
    canActivate: [MsalGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    initialNavigation: 'enabledNonBlocking',
    enableViewTransitions: true,
    bindToComponentInputs: true,
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
