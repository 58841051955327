<table mat-table [dataSource]="begehungen" class="mat-elevation-z8 sticky-table color-table">
    <ng-container matColumnDef="datum">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let begehung ">{{begehung.datum | date:'dd.MM.yyyy'}}</td>
    </ng-container>

    <ng-container matColumnDef="userCreated">
        <th mat-header-cell *matHeaderCellDef>Erstellter Benutzer</th>
        <td mat-cell *matCellDef="let begehung ">{{begehung.userCreated}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let begehung ">{{begehung.name}}</td>
    </ng-container>

    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let begehung ">
            <button mat-mini-fab color="primary" [routerLink]="['/begehung/offen/karte', begehung.id]" matTooltip="auf Karte"><mat-icon>map</mat-icon></button>
            <button class="left-space" mat-mini-fab color="primary" [routerLink]="['/begehung/offen', begehung.id]" matTooltip="als Liste"><mat-icon>list</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row  *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
