<mat-toolbar color="primary">
    <button mat-fab extended routerLink="/reparatur/schaeden/karte"><mat-icon>map</mat-icon>Karte</button>
</mat-toolbar>

<table mat-table [dataSource]="(team | async)?.arbeitsauftrage" class="mat-elevation-z8 sticky-table color-table">
    <ng-container matColumnDef="nummer">
        <th mat-header-cell *matHeaderCellDef>Nummer</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{arbeitsauftrag.nummer}}</td>
    </ng-container>
    <ng-container matColumnDef="beschreibung">
        <th mat-header-cell *matHeaderCellDef>Beschreibung</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{arbeitsauftrag.beschreibung}}</td>
    </ng-container>
    <ng-container matColumnDef="datum">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{arbeitsauftrag.schaden.datum | date:'dd.MM.yyyy HH:mm'}}</td>
    </ng-container>
    <ng-container matColumnDef="prioritaet">
        <th mat-header-cell *matHeaderCellDef>Priorität</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{arbeitsauftrag.schaden.prioritaet.name}}</td>
    </ng-container>
    <ng-container matColumnDef="teilanlage">
        <th mat-header-cell *matHeaderCellDef>Teilanlage</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{arbeitsauftrag.schaden.teilanlage.name}}</td>
    </ng-container>
    <ng-container matColumnDef="schadenart">
        <th mat-header-cell *matHeaderCellDef>Schadenart</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{arbeitsauftrag.schaden.schadenart.name}}</td>
    </ng-container>
    <ng-container matColumnDef="strassenabschnitt">
        <th mat-header-cell *matHeaderCellDef>Straßenabschnitt</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{getStrassenabschnitt(arbeitsauftrag.schaden)}}</td>
    </ng-container>

    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let arbeitsauftrag">
            <button mat-mini-fab color="primary" (click)="openSchadenDialog(arbeitsauftrag)"><mat-icon>info</mat-icon></button>
            <!--
            <button mat-mini-fab color="primary" [routerLink]="['/teams', team.id, 'schaden', 'list']"><mat-icon>list</mat-icon></button>
            <button mat-mini-fab color="primary" class="left-space" [routerLink]="['/teams', team.id, 'schaden','karte']"><mat-icon>map</mat-icon></button>
            <button mat-mini-fab color="warn" class="left-space" (click)="deleteTeam(team)"><mat-icon>delete</mat-icon></button>
            -->
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
