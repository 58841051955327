import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Map, View } from 'ol';
import { Attribution, defaults as defaultControls } from 'ol/control';
import LayerSwitcher from 'ol-layerswitcher/dist/ol-layerswitcher';

@Component({
  selector: 'app-basis-karte',
  templateUrl: './basis-karte.component.html',
  styleUrls: ['./basis-karte.component.scss'],
})
export class BasisKarteComponent implements AfterViewInit {

  map!: Map;
  view!: View;

  @ViewChild("mapx", {static: true})
  _mapx!: ElementRef;

  constructor() {
  }

  ngAfterViewInit(): void {
    this.view = new View({
      center: [0, 0],
      zoom: 1,
      projection: "EPSG:3857",
    });

    const attribution = new Attribution({
      collapsible: true,
    });

    this.map = new Map({
      maxTilesLoading: 16,
      view: this.view,
      controls: defaultControls({attribution: false}).extend([attribution]),
      target: this._mapx.nativeElement,
    });

    const layerSwitcher = new LayerSwitcher({});
    this.map.addControl(layerSwitcher);
  }

  public getMap(): Map {
    return this.map;
  }

  public getView(): View {
    return this.view;
  }
}
