<div #schadenPopup id="ol-euv-schaden-popup" class="ol-popup">
  <div id="popup-content">
    @if (!(_loading | async)) {
      <div>
        <div>Datum: <span class="schaden-data">{{_selectedSchaden?.datum | date:'dd.MM.yyyy'}}</span></div>
        <div>Straßenabschnitt: <span class="schaden-data">{{_selectedSchaden?.strassenabschnitt ? _selectedSchaden?.strassenabschnitt.bezeichnung : _selectedSchaden?.zubegehenStrassenabschnitt.bezeichnung}}</span></div>
        <div>
          @for (foto of _selectedSchaden?.fotos; track foto) {
            <span>
              <img class="ol-popup-img" [src]="foto.foto" (click)="displayFoto(foto.foto)">
            </span>
          }
        </div>
      </div>
    } @else {
      Lade Daten..
    }
  </div>
</div>
