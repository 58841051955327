<h1>Teamauswahl</h1>
<form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field>
        <mat-label>Team</mat-label>
        <mat-select formControlName="team">
            @for(team of teams; track team.id) {
                <mat-option [value]="team">{{team.name}}</mat-option>
            }
        </mat-select>
    </mat-form-field>
    <br/>
    <button mat-flat-button color="primary" [disabled]="!form.valid">Auswählen</button>
</form>
