<h1>Teams</h1>
<table mat-table [dataSource]="_arbeitsauftraege" class="mat-elevation-z8 sticky-table color-table">
    <ng-container matColumnDef="nummer">
        <th mat-header-cell *matHeaderCellDef>Nummer</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{arbeitsauftrag.nummer}}</td>
    </ng-container>

    <ng-container matColumnDef="prioritaet">
        <th mat-header-cell *matHeaderCellDef>Priorität</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{arbeitsauftrag.schaden.prioritaet.name}}</td>
    </ng-container>

    <ng-container matColumnDef="teilanlage">
        <th mat-header-cell *matHeaderCellDef>Teilanlage</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{arbeitsauftrag.schaden.teilanlage.name}}</td>
    </ng-container>

    <ng-container matColumnDef="schadenart">
        <th mat-header-cell *matHeaderCellDef>Schadenart</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{arbeitsauftrag.schaden.schadenart.name}}</td>
    </ng-container>

    <ng-container matColumnDef="strassenabschnitt">
        <th mat-header-cell *matHeaderCellDef>Straßenabschnitt</th>
        <td mat-cell *matCellDef="let arbeitsauftrag">{{getStrassenabschnittBezeichnung(arbeitsauftrag.schaden)}}</td>
    </ng-container>

    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let arbeitsauftrag">
            <button mat-mini-fab color="primary" (click)="openInfoDialog(arbeitsauftrag.schaden.id)"><mat-icon>info</mat-icon></button>
            <button mat-mini-fab color="warn" (click)="deleteAuftrag(arbeitsauftrag)" class="left-space"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="_displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: _displayedColumns;"></tr>
</table>
