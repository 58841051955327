import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QueryRef } from 'apollo-angular';
import { Observable, map, take, tap } from 'rxjs';
import { QuestionDialogComponent } from 'src/app/helper/question-dialog/question-dialog.component';
import { SchadenInfoDialogComponent } from 'src/app/schaeden/schaden-info-dialog/schaden-info-dialog.component';
import { ArbeitsauftragService } from 'src/app/services/arbeitsauftrag.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { TeamService } from 'src/app/services/team.service';

@UntilDestroy()
@Component({
  selector: 'app-team-schaden-list',
  templateUrl: './team-schaden-list.component.html',
  styleUrl: './team-schaden-list.component.scss'
})
export class TeamSchadenListComponent implements OnInit, OnDestroy {

  @Input({required: true})
  teamId!: string;

  _displayedColumns = [
    "nummer",
    "prioritaet",
    "teilanlage",
    "schadenart",
    "strassenabschnitt",
    "tools",
  ];


  _arbeitsauftraege!: Observable<any>;
  _arbeitsauftraegeRef!: QueryRef<any>;

  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private teamService: TeamService,
    private progressbarService: ProgressBarService,
    private arbeitsauftragService: ArbeitsauftragService,
  ) {}

  ngOnInit(): void {
    this.progressbarService.clear();

    console.log("TeamId:", this.teamId);
    this._arbeitsauftraegeRef = this.teamService.findTeamByIdWithSchaeden(this.teamId);
    this.progressbarService.add();
    this._arbeitsauftraege = this._arbeitsauftraegeRef.valueChanges.pipe(untilDestroyed(this), map((r) => r.data.findTeamById.arbeitsauftrage),tap(() => this.progressbarService.remove()));
  }

  ngOnDestroy(): void {
    
  }

  getStrassenabschnittBezeichnung(schaden: any): string {
    if(schaden.strassenabschnitt) {
      return schaden.strassenabschnitt.bezeichnung;
    } else {
      return schaden.zubegehenStrassenabschnitt.bezeichnung;
    }
  }

  openInfoDialog(schadenId: string): void {
    const ref = this.dialog.open(SchadenInfoDialogComponent, {
      data: {
        id: schadenId
      }
    });
  }

  deleteAuftrag(auftrag: any): void {
    const ref = this.dialog.open(QuestionDialogComponent, {
      data: {
        title: "Auftrag löschen",
        message: "Soll der Auftrag mit der Nummer "+ auftrag.nummer +" wirklich gelöscht werden?"
      },
    });

    ref.afterClosed().subscribe((r) => {
      if(r && r.result) {
        this.progressbarService.add();
        this.arbeitsauftragService.deleteArbeitsauftragBySchadenId(auftrag.schaden.id).pipe(take(1)).subscribe({
          next: (r) => {
            this.progressbarService.remove();
            this.progressbarService.add();
            this._arbeitsauftraegeRef.refetch();
          },
          error: (err) => {
            this.snackbar.open("Fehler beim Löschen des Auftrags: "+ err, "Ok");
          },
        });
      }
    });
  }
}
