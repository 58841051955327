import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {

  angularPlugin: AngularPlugin;
  appInsights: ApplicationInsights;

  constructor(
    private configService: ConfigService,
    private router: Router,
  ) {

    var telemetry = (envelope: any) => {
      envelope.tags["ai.cloud.role"] = environment.app_role;
      envelope.tags["ai.cloud.roleInstance"] = environment.app_instance;
    }

    this.angularPlugin = new AngularPlugin();

    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: this.configService.appInsightsConnectionString,
        appId: "webgui",
        enableAjaxErrorStatusText: true,
        enableAjaxPerfTracking: true,
        extensions: [this.angularPlugin],
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensionConfig: {
          [this.angularPlugin.identifier]: { router: this.router },
        }
      }
    });

    this.appInsights.addTelemetryInitializer(telemetry);

    this.appInsights.loadAppInsights();

    console.log("Load appInsights");
  }
}
