import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map, take, tap } from 'rxjs';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { StateTeamService } from 'src/app/services/state-team.service';
import { TeamService } from 'src/app/services/team.service';

@UntilDestroy()
@Component({
  selector: 'app-reperatur-team-wahl',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
  ],
  templateUrl: './reparatur-team-wahl.component.html',
  styleUrl: './reparatur-team-wahl.component.scss'
})
export class ReparaturTeamWahlComponent implements OnInit, OnDestroy{

  teams!: [any];

  form: FormGroup = new FormGroup({
    team: new FormControl(null, Validators.required),
  });

  constructor(
    private progressBarService: ProgressBarService,
    private stateTeamService: StateTeamService,
    private teamService: TeamService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.progressBarService.clear();
    this.progressBarService.add();
    this.teamService.findAllTeams(true).valueChanges.pipe(untilDestroyed(this)).subscribe((r) => {
      this.progressBarService.remove()
      this.teams = r.data.findAllTeams;

      /* Set selected team */
      if(this.stateTeamService.id) {
        this.teams.forEach((element) => {
          if(element.id === this.stateTeamService.id) {
            this.form.controls["team"].setValue(element);
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    
  }

  submit(): void {
    console.log("Value:", this.form.value);
    if(this.form.value.team) {
      const team = this.form.value.team;

      this.stateTeamService.id = team.id;
      this.stateTeamService.name = team.name;

      this.router.navigate(["/reparatur/schaeden"]);
    }
  }
}
