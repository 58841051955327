import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QueryRef } from 'apollo-angular';
import { map, take, tap } from 'rxjs';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { TeamService } from 'src/app/services/team.service';
import { TeamCreateDialogComponent } from '../team-create-dialog/team-create-dialog.component';
import { QuestionDialogComponent } from 'src/app/helper/question-dialog/question-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrl: './team-list.component.scss'
})
export class TeamListComponent implements OnInit, OnDestroy {

  teams: any;

  _teamQueryRef!: QueryRef<any>;

  displayedColumns = [
    "name",
    "tools",
  ];

  constructor(
    private teamService: TeamService,
    private progressBarService: ProgressBarService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.progressBarService.add();
    this._teamQueryRef = this.teamService.findAllTeams();
    this.teams = this._teamQueryRef.valueChanges.pipe(untilDestroyed(this), map(result => result.data.findAllTeams), tap(() => this.progressBarService.remove()));
  }

  ngOnDestroy(): void {
    
  }

  createTeam(): void {
    const dialogRef = this.dialog.open(TeamCreateDialogComponent,{
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.name) {
        this.progressBarService.add();
        this.teamService.createTeam(result.name).pipe(take(1), tap(() => this.progressBarService.remove())).subscribe({
          next: (result) => {
            this._teamQueryRef.refetch();
            this.snackBar.open("Team erstellt", "", {duration: 1000});
          },
          error: (err) => {
            console.error(err);
            this.snackBar.open("Fehler beim erstellen:"+ err, "OK");
          },
        });
      }
    });
  }

  deleteTeam(team: any): void {
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      data: {
        title: "Team löschen",
        message: "Soll das Team "+ team.name +" wirklich gelöscht werden"
      }
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if(result?.result) {
          console.log(result);
          this.progressBarService.add();
          this.teamService.deleteTeam(team.id).pipe(take(1), tap(() => this.progressBarService.remove())).subscribe({
            next: (result) => {
              this.snackBar.open("Team gelöscht", "", {duration: 1000});
              this._teamQueryRef.refetch();
            },
            error: (err) => {
              console.error(err);
              this.snackBar.open("Fehler beim löschen: "+ err, "OK");
            }
          });
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
}
