import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { ConfigService } from './services/config.service';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if(graphQLErrors) {
    graphQLErrors.map(({ message, locations, path}) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`);
    });
  }
  if(networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const noNgswCacheHeader = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "ngsw-bypass": "true",
    }
  }))

  return forward(operation);
});

export function createApollo(httpLink: HttpLink, configService: ConfigService): ApolloClientOptions<any> {
  return {
    link: ApolloLink.from([
      new RetryLink({
        attempts: (count, operation, error) => {
          if(count > 20) return false;
          return !!error && operation.operationName != 'specialCase';
        },
        delay: (count, operation, error) => {
          return count * 1000 * Math.random();
        }
      }),
      errorLink,
      noNgswCacheHeader,
      httpLink.create({uri: configService.api_url,})
    ]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ConfigService],
    },
  ],
})
export class GraphQLModule {}
