import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map, take, tap } from 'rxjs';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { StateTeamService } from 'src/app/services/state-team.service';
import { TeamService } from 'src/app/services/team.service';
import { ReparaturSchadenDialogComponent } from '../reparatur-schaden-dialog/reparatur-schaden-dialog.component';
import { QueryRef } from 'apollo-angular';

@UntilDestroy()
@Component({
  selector: 'app-reparatur-list',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    RouterModule,
    MatDialogModule,
  ],
  templateUrl: './reparatur-list.component.html',
  styleUrl: './reparatur-list.component.scss'
})
export class ReparaturListComponent implements OnInit, OnDestroy {

  team!: Observable<any>;

  displayedColumns = [
    "nummer",
    "datum",
    "beschreibung",
    "prioritaet",
    "teilanlage",
    "schadenart",
    "strassenabschnitt",
    "tools",
  ];

  _queryRef!: QueryRef<any>;

  constructor(
    private progressBarService: ProgressBarService,
    private stateTeamService: StateTeamService,
    private teamService: TeamService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.progressBarService.clear();
    if(this.stateTeamService.id) {
      console.log("Load team");
      this._queryRef = this.teamService.findTeamByIdWithSchaeden(this.stateTeamService.id);
      this.team = this._queryRef.valueChanges.pipe(untilDestroyed(this), map(r => r.data.findTeamById));
    }
  }

  ngOnDestroy(): void {
    
  }

  getStrassenabschnitt(schaden: any): string {
    return schaden.strassenabschnitt?.bezeichnung ? schaden.strassenabschnitt.bezeichnung : schaden.zubegehenStrassenabschnitt.bezeichnung;
  }

  openSchadenDialog(arbeitsauftrag: any): void {
    const dialogRef = this.dialog.open(ReparaturSchadenDialogComponent, {
      data: {
        arbeitsauftrag: arbeitsauftrag,
      }
    });

    dialogRef.afterClosed().subscribe((r) => {
      console.log("Dialog: ", r);
      this._queryRef.refetch();
    })
  }
}
