import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

interface MapConfig {
  center: [number, number],
  zoom: number,
};

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public api_url: string = "";
  public api_id: string = "";
  public authority: string = "";
  public clientId: string = "";
  public redirectUri: string = "";
  public geoserverWmsUrl: string = "";
  public geoserverWmsUrls: [] = [];
  public map: MapConfig = {
    center: [814112, 6722795],
    zoom: 12
  };
  public appInsightsConnectionString: string = "";

  private http: HttpClient;

  constructor(private httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
  }

  load() {
    const promise = firstValueFrom(this.http.get("assets/app.config.json")).then(d => {
      Object.assign(this, d);
    });
    return promise;
  }
}
