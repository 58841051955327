<div class="all-container">
  <div class="header">
    <mat-toolbar>
      <button mat-icon-button (click)="sidenavService.toggle()"><mat-icon>menu</mat-icon></button>
      <span><a mat-button routerLink="/" mat->Straßenkontrolle</a></span>
      <span>@if (progressBarService.display()) {
        <mat-progress-spinner mode="indeterminate" class="spinner"></mat-progress-spinner>
      }</span>
      @if(securityService.isStrassenwaerter()) {
        <button mat-button class="team" routerLink="/reparatur">{{stateTeamService.name}}</button>
      }
    </mat-toolbar>
  </div>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav>
      <app-side-menu></app-side-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="main-content">
      <router-outlet></router-outlet>
    </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <div class="footer">
    <a [routerLink]="" (click)="displayBackendVersionInfo()"><app-version-info></app-version-info></a><span> - </span>
    <span>&copy;2023-2024 by EUV Stadtbetrieb Castrop-Rauxel -AöR-</span>
  </div>
</div>
