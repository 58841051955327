import { AfterViewInit, Component, Input } from '@angular/core';
import { BasisKarteComponent } from '../basis-karte/basis-karte.component';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import { OSM as OSMSource } from 'ol/source';

@Component({
  selector: 'app-osm-layer',
  templateUrl: './osm-layer.component.html',
  styleUrls: ['./osm-layer.component.scss']
})
export class OsmLayerComponent implements AfterViewInit {

  @Input()
  karte!: BasisKarteComponent;

  @Input()
  visible: boolean = false;

  @Input()
  zIndex: number = 0;

  _layerOSM!: WebGLTileLayer;
  _sourceOSM!: OSMSource;

  ngAfterViewInit(): void {
    this.initOsmLayer();
  }

  initOsmLayer(): void {
    this._sourceOSM = new OSMSource();
    this._layerOSM = new WebGLTileLayer({
      title: 'OSM',
      type: 'base',
      visible: this.visible,
      zIndex: this.zIndex,
      source: this._sourceOSM,
    } as any);

    this.karte.getMap().addLayer(this._layerOSM);
  }

  public getLayer(): WebGLTileLayer {
    return this._layerOSM;
  }

  public getSource(): OSMSource {
    return this._sourceOSM;
  }
}
