import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasisKarteComponent } from '../basis-karte/basis-karte.component';
import { Feature, Geolocation } from 'ol';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { Point } from 'ol/geom';
import { EventsKey } from 'ol/events';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Size } from 'ol/size';
import { Control } from 'ol/control';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-gps-tracking',
  templateUrl: './gps-tracking.component.html',
  styleUrls: ['./gps-tracking.component.scss']
})
export class GpsTrackingComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  karte!: BasisKarteComponent;

  @Input()
  continue: boolean = false;

  @Input()
  resolution: number = 0.1;

  @ViewChild("centerControl", {static: true})
  _centerControl!: ElementRef;

  _geolocation!: Geolocation;
  _positionFeature!: Feature;
  _accurayFeature!: Feature;
  _firstLocation: boolean = true;

  _eventKeyPosition!: EventsKey;
  _eventKeyAccuracy!: EventsKey;

  _source!: VectorSource;
  _layer!: VectorLayer<VectorSource>;

  _controlCenter!: Control;

  ngOnInit(): void {
    
  }
  
  ngAfterViewInit(): void {
    this.initGeolocation();
    this.initFeatures();

    this.initEvents();

    this.addLayers();
    this.initControls();
  }

  ngOnDestroy(): void {
    this._geolocation.un("change:position", this._eventKeyPosition.listener);
    this._geolocation.un("change:accuracyGeometry", this._eventKeyAccuracy.listener);

    this.karte.getMap().removeControl(this._controlCenter);
    this.karte.getMap().removeLayer(this._layer);
  }


  initControls(): void {
    this._controlCenter = new Control({element: this._centerControl.nativeElement});
    this.karte.getMap().addControl(this._controlCenter);
  }

  initGeolocation(): void {
    this._geolocation = new Geolocation({
      tracking: true,
      trackingOptions: {
        maximumAge: 1000,
        enableHighAccuracy: true,
      },
      projection: this.karte.getView().getProjection(),
    });
  }

  initFeatures(): void {
    this._positionFeature = new Feature();
    this._positionFeature.setStyle(new Style({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: "rgba(210, 210, 0, 0.6)",
        }),
        stroke: new Stroke({
          color: "rgba(255, 255, 255, 0.8)",
          width: 2,
        }),
      }),
    }));

    this._accurayFeature = new Feature();
  }

  initEvents(): void {
    this._eventKeyPosition = this._geolocation.on("change:position", () => {
      const coordinates = this._geolocation.getPosition();
      this._positionFeature.setGeometry(coordinates ? new Point(coordinates) : undefined);
    });

    this._eventKeyAccuracy = this._geolocation.on("change:accuracyGeometry", () => {
      const geom = this._geolocation.getAccuracyGeometry();
      if(geom) {
        this._accurayFeature.setGeometry(geom);
        if(this._firstLocation || this.continue) {
          this.karte.getView().fit(geom, {
            nearest: true,
            padding: [20, 20, 20, 20],
            minResolution: this.resolution,
          });
          this._firstLocation = false;
        }
      }
    });
  }

  addLayers(): void {
    this._source = new VectorSource({
      features: [this._positionFeature, this._accurayFeature],
      useSpatialIndex: false,
    });

    this._layer = new VectorLayer({
      map: this.karte.getMap(),
      title: "GPS Tracking",
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      source: this._source,
    } as any);
  }

  centerView(): void {
    const pos = this._geolocation.getPosition();
    const size = this.karte.getMap().getSize() as Size;
    if(pos) this.karte.getView().centerOn(pos, size, [size[0] / 2, size[1] / 2]);
  }

  public getLayer(): VectorLayer<VectorSource> {
    return this._layer;
  }
  public getSource(): VectorSource {
    return this._source;
  }
  public getGeolocation(): Geolocation {
    return this._geolocation;
  }
}
