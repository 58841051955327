import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { AbschnittMenuComponent } from 'src/app/karte/abschnitt-menu/abschnitt-menu.component';
import { CreateSchadenComponent } from 'src/app/karte/create-schaden/create-schaden.component';
import { GpsTrackingComponent } from 'src/app/karte/gps-tracking/gps-tracking.component';
import { SchaedenLayerComponent } from 'src/app/karte/schaeden-layer/schaeden-layer.component';
import { SelectAbschnittComponent } from 'src/app/karte/select-abschnitt/select-abschnitt.component';
import { SchadenStateService } from 'src/app/services/schaden-state.service';
import { SchadenService } from 'src/app/services/schaden.service';
import { SelectedFeatureEvent } from 'src/app/types/selected-feature-event';
import { CreateDialogComponent } from '../../create-dialog/create-dialog.component';
import { toLonLat } from 'ol/proj';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-karte-neuer-schaden',
  templateUrl: './karte-neuer-schaden.component.html',
  styleUrls: ['./karte-neuer-schaden.component.scss']
})
export class KarteNeuerSchadenComponent {

  @ViewChild("selectAbschnitt")
  selectAbschnittComponent!: SelectAbschnittComponent;
  @ViewChild("abschnittMenu")
  abschnittMenuComponent!: AbschnittMenuComponent;
  @ViewChild("gps")
  _gps!: GpsTrackingComponent;
  @ViewChild("schadenLayer")
  _schadenLayer!: SchaedenLayerComponent;
  @ViewChild("createSchaden")
  _createSchadenComponent!: CreateSchadenComponent;

  _strassenabschnitt: Feature|undefined;



  constructor(private schadenStateService: SchadenStateService,
    private schadenService: SchadenService,
    private snackBar: MatSnackBar,
    private progressBarService: ProgressBarService,
    private dialog: MatDialog) {
  }

  newSchadenPoint(e: SelectedFeatureEvent): void {
    console.debug("newSchaden:", e);
    this.schadenStateService.reset();
    this.schadenStateService.clearSession();
    this._strassenabschnitt = e.feature;
    const geo = this._gps.getGeolocation();
    const c = geo.getPosition();
    if(c) {
      const f = new Feature({
        geometry: new Point(c),
        projection: geo.getProjection(),
      });

      this.selectAbschnittComponent.disableInteraction();
      this.abschnittMenuComponent.closePopup();

      this._createSchadenComponent.addSchadenAt(f);
      this._createSchadenComponent.enableControl();
      this._createSchadenComponent.enableLayer();
      this._createSchadenComponent.enableInteraction();
    }
  }

  createSchadenAt(e: Feature): void {
    console.debug("Commited Schaden:", e);
    console.debug("At Strassenabschnitt:", this._strassenabschnitt);

    this.schadenStateService.strassenabschnittId = this._strassenabschnitt?.getId() as string;

    const point = e.getGeometry() as Point;
    const lonLat = toLonLat(point.getCoordinates());
    this.schadenStateService.lon = lonLat[0];
    this.schadenStateService.lat = lonLat[1];

    this._createSchadenComponent.disableInteraction();
    this._createSchadenComponent.disableLayer();
    this._createSchadenComponent.disableControl();

    this.dialog.open(CreateDialogComponent, {
      hasBackdrop: false,
    }).afterClosed().subscribe(result => {
      console.debug("Dialog closed", result);
      if(result === "submit") {
        this.saveSchaden();
      }

      this.selectAbschnittComponent.enableInteraction();
    });
  }

  cancelSchaden(): void {
    console.debug("Cancel add schaden");

    this._createSchadenComponent.disableInteraction();
    this._createSchadenComponent.disableLayer();
    this._createSchadenComponent.disableControl();

    this.selectAbschnittComponent.enableInteraction();
  }

  saveSchaden(): void {
    this.progressBarService.add();
    this.schadenService.createNewWithStrassenabschnitt(
      this.schadenStateService.strassenabschnittId as string,
      this.schadenStateService.prioritaetId as string,
      this.schadenStateService.teilanlageId as string,
      this.schadenStateService.schadenartId as string,
      new Date(),
      this.schadenStateService.lon as number,
      this.schadenStateService.lat as number,
      this.schadenStateService.laenge as number,
      this.schadenStateService.breite as number,
      this.schadenStateService.tiefe as number,
      this.schadenStateService.bemerkung as string
    ).pipe(take(1)).subscribe({
      next: result => {
        this.progressBarService.remove();
        console.log("Saved schaden:", result);
        const schadenId = result.data.createSchaden.id;

        this.snackBar.open("Schaden gespeichert", "", {
          duration: 1000,
        });
        this._schadenLayer.refetch();

        for(const foto of this.schadenStateService.fotos) {
          this.progressBarService.add();
          this.schadenService.addFoto(foto, schadenId).pipe(take(1)).subscribe({
            next: result => {
              this.progressBarService.remove();
              console.log("Add Foto:", result);
              this.snackBar.open("Foto gespeichert", "", {
                duration: 1000,
              });
            },
            error: error => {
              this.progressBarService.remove();
              this.snackBar.open("Fehler beim speichern des Fotos:"+ error, "OK");
            }
          });
        }
      },
      error: error => {
        this.progressBarService.remove();

        this.snackBar.open("Fehler beim speichern des Schaden:"+ error, "Ok");
      }
    });
  }
}
