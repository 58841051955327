<h1 mat-dialog-title>Schaden löschen</h1>

<div mat-dialog-content>
    <table>
        <tr><th>Datum:</th><td>{{data.datum | date:'dd.MM.yyyy HH:mm'}}</td></tr>
        <tr><th>Priorität</th><td>{{data.prioritaet}}</td></tr>
        <tr><th>Straßenabschnitt</th><td>{{data.strassenabschnitt}}</td></tr>
        <tr><th>Teilanlage</th><td>{{data.teilanlage}}</td></tr>
        <tr><th>Erstellt von:</th><td>{{data.userCreated}}</td></tr>
        <tr><th>Erstellt um:</th><td>{{data.createdAt | date:'dd.MM.yyyy HH:mm'}}</td></tr>
    </table>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="clickYes()">Ja</button>
    <button mat-button (click)="clickNo()" cdkFocusInitial>Nein</button>
</div>
