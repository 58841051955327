import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map, take } from 'rxjs';
import { SchadenService } from 'src/app/services/schaden.service';

@UntilDestroy()
@Component({
  selector: 'app-schaden-info-dialog',
  templateUrl: './schaden-info-dialog.component.html',
  styleUrl: './schaden-info-dialog.component.scss'
})
export class SchadenInfoDialogComponent implements OnInit, OnDestroy {

  schaden!: Observable<any>;

  constructor(
    public dialog: MatDialogRef<SchadenInfoDialogComponent>,
    @Inject(DIALOG_DATA) public data: {id: string},
    private schadenService: SchadenService,
  ) {
  }

  ngOnInit(): void {
    this.schaden = this.schadenService.findSchadenById(this.data.id).valueChanges.pipe(take(1), map(r => r.data.findSchadenById));
  }

  ngOnDestroy(): void {
    
  }

  getStrassenabschnitt(schaden: any): string {
    return schaden?.strassenabschnitt?.bezeichnung ? schaden?.strassenabschnitt?.bezeichnung : schaden?.zubegehenStrassenabschnitt?.bezeichnung;
  }
}
