import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QueryRef } from 'apollo-angular';
import { Collection, Feature } from 'ol';
import { Control } from 'ol/control';
import { platformModifierKeyOnly, singleClick } from 'ol/events/condition';
import { Geometry, Point } from 'ol/geom';
import { DragBox, Select } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, RegularShape, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { take } from 'rxjs';
import { BasisKarteComponent } from 'src/app/karte/basis-karte/basis-karte.component';
import { ArbeitsauftragService } from 'src/app/services/arbeitsauftrag.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { SchadenService } from 'src/app/services/schaden.service';

@UntilDestroy()
@Component({
  selector: 'app-team-layer-schaeden',
  templateUrl: './team-layer-schaeden.component.html',
  styleUrl: './team-layer-schaeden.component.scss'
})
export class TeamLayerSchaedenComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  karte!: BasisKarteComponent;

  @Input()
  team!: any;

  @Input()
  inaktive: boolean = true;

  @Input()
  tolerance: number = 12;

  @Input()
  zIndex: number = 10;

  _queryRef!: QueryRef<any>;
  _filter: any;

  _featuresHiddenOhneAuftrag: any;
  _featuresDisplayedOhneAuftrag: Collection<Feature> = new Collection();
  _featuresHiddenMitAuftrag: any;
  _featuresDisplayedMitAuftrag: Collection<Feature> = new Collection();

  _sourceOhneAuftrag!: VectorSource;
  _sourceMitAuftrag!: VectorSource;

  _layerOhneAuftrag!: VectorLayer<VectorSource>;
  _layerMitAuftrag!: VectorLayer<VectorSource>;


  _controlDragBox!: any;
  _controlSelect!: Select;

  _selectedFeatures!: Collection<Feature<Geometry>>;

  _filterPrioritaetId: any;
  _filterSchadenartId: any = [];
  _filterTeilanlageId: any = [];

  @ViewChild("addSelectionToTeamButton", {static: true})
  _addSelectionToTeamButton!: ElementRef;
  _addSelectionToTeamControl!: Control;

  @ViewChild("removeSelectionFromTeamButton", {static: true})
  _removeSelectionFromTeamButton!: ElementRef;
  _removeSelectionFromTeamControl!: Control;

  constructor(private progressBarService: ProgressBarService,
    private schadenService: SchadenService,
    private arbeitsauftragService: ArbeitsauftragService,
  ) {

  }

  ngOnInit(): void {
    
  }
  
  ngAfterViewInit(): void {

    this.loadData();

    this.addLayers();
    this.addSelection();

    this.initControl();
  }

  ngOnDestroy(): void {
    this.karte.getMap().removeLayer(this._layerMitAuftrag);
    this.karte.getMap().removeLayer(this._layerOhneAuftrag);

    this.karte.getMap().removeInteraction(this._controlSelect);
    this.karte.getMap().removeInteraction(this._controlDragBox);

    this.karte.getMap().removeControl(this._addSelectionToTeamControl);
    this.karte.getMap().removeControl(this._removeSelectionFromTeamControl);
  }


  @Input()
  set filterPrioritaet(prioritaet: any) {
    console.log("Prioritaet:", prioritaet);
    this._filterPrioritaetId = prioritaet;

    this.updateDisplayedFeatures();
  }

  @Input()
  set filterTeilanlage(teilanlage: any) {
    console.log("Teilanlage:", teilanlage);
    this._filterTeilanlageId = teilanlage;

    this.updateDisplayedFeatures();
  }

  @Input()
  set filterSchadenart(schadenart: any) {
    console.log("Schadenart:", schadenart);
    this._filterSchadenartId = schadenart;

    this.updateDisplayedFeatures();
  }

  addSelectionToTeam(): void {
    console.log("Add");
    this._selectedFeatures.forEach((element, index, array) => {
      this.progressBarService.add();
      this.arbeitsauftragService.createArbeitsauftrag(this.team.id, element.get("data").id).pipe(take(1)).subscribe(() => {
        this.progressBarService.remove();
        if(index+1 === array.length) {
          this.progressBarService.add();
          this._queryRef.refetch();;
        }
      });
    });
  }

  removeSelectionFromTeam(): void {
    this.progressBarService.add();
    this._selectedFeatures.forEach((element, index, array) => {
      this.arbeitsauftragService.deleteArbeitsauftragBySchadenId(element.get("data").id).pipe(take(1)).subscribe(() => {
        this.progressBarService.remove();
        if(index+1 === array.length) {
          this.progressBarService.add();
          this._queryRef.refetch();
        }
      });
    })
  }

  private addLayers(): void {
    this._sourceMitAuftrag = new VectorSource({
      features: this._featuresDisplayedMitAuftrag,
    });
    this._layerMitAuftrag = new VectorLayer({
      source: this._sourceMitAuftrag,
      zIndex: this.zIndex,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      title: "mit Auftrag",
    } as any);

    this._sourceOhneAuftrag = new VectorSource({
      features: this._featuresDisplayedOhneAuftrag,
    });
    this._layerOhneAuftrag = new VectorLayer({
      source: this._sourceOhneAuftrag,
      zIndex: this.zIndex,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      title: "ohne Auftrag",
    } as any);

    this.karte.getMap().addLayer(this._layerMitAuftrag);
    this.karte.getMap().addLayer(this._layerOhneAuftrag);
  }

  private loadData(): void {
    this._filter = {
      active: true
    };
    this._queryRef = this.schadenService.findSchadenWithFilter(this._filter, false);
    this.progressBarService.add();
    this._queryRef.valueChanges.pipe(untilDestroyed(this)).subscribe(result => {
      this.progressBarService.remove();
      this._featuresHiddenMitAuftrag = [];
      this._featuresHiddenOhneAuftrag = [];
      this._selectedFeatures.clear();

      const proj = this.karte.getView().getProjection();

      /* Load schaeden */
      for(const schaden of result.data.findSchadenWithFilter) {
        if(schaden.active) {
          const geom = new Point(fromLonLat([schaden.lon, schaden.lat], proj));
          if(schaden.arbeitsauftrageVorhanden) {
            if(schaden.arbeitsauftrage[0].team.id === this.team?.id) {
              const style = new Style({
                image: new CircleStyle({
                  radius: 9,
                  fill: new Fill({
                    color: "rgba(0, 0, 255, 1.0)",
                  }),
                  stroke: new Stroke({
                    color: schaden.prioritaet.color,
                    width: 3,
                  }),
                }),
              });
              const feature = new Feature({
                geometry: geom,
                name: schaden.prioritaet.name,
                data: schaden,
              });
              feature.setStyle(style);
              feature.setId(schaden.id);
              this._featuresHiddenMitAuftrag.push(feature);
            }
          } else {
            const style = new Style({
              image: new RegularShape({
                radius: 11,
                points: 3,
                fill: new Fill({
                  color: schaden.prioritaet.color,
                }),
                stroke: new Stroke({
                  color: "rgba(255, 255, 255, 1)",
                  width: 3,
                })
              })
            });
            const feature = new Feature({
              geometry: geom,
              name: schaden.prioritaet.name,
              data: schaden,
            });
            feature.setStyle(style);
            feature.setId(schaden.id);
            this._featuresHiddenOhneAuftrag.push(feature);
          }
        }
      }

      this.updateDisplayedFeatures();
    });
  }

  private updateDisplayedFeatures(): void {
    this._featuresDisplayedMitAuftrag.clear();
    this._featuresHiddenMitAuftrag?.forEach((element: Feature<Geometry>) => {
      if(
        (!this._filterPrioritaetId || element.get("data").prioritaet.id === this._filterPrioritaetId)
        && (!(this._filterTeilanlageId && this._filterTeilanlageId?.length>0) || this._filterTeilanlageId?.includes(element.get("data").teilanlage.id))
        && (!(this._filterSchadenartId && this._filterSchadenartId?.length>0) || this._filterSchadenartId?.includes(element.get("data").schadenart.id))
      ) {
        this._featuresDisplayedMitAuftrag.push(element);
      }
    });

    this._featuresDisplayedOhneAuftrag.clear();
    this._featuresHiddenOhneAuftrag?.forEach((element: Feature<Geometry>) => {
      if(
        (!this._filterPrioritaetId || element.get("data").prioritaet.id === this._filterPrioritaetId)
        && (!(this._filterTeilanlageId && this._filterTeilanlageId?.length>0) || this._filterTeilanlageId?.includes(element.get("data").teilanlage.id))
        && (!(this._filterSchadenartId && this._filterSchadenartId?.length>0) || this._filterSchadenartId?.includes(element.get("data").schadenart.id))
      ) {
        this._featuresDisplayedOhneAuftrag.push(element);
      }
    });
  }

  private initControl(): void {
    this._addSelectionToTeamControl = new Control({element: this._addSelectionToTeamButton.nativeElement});
    this._removeSelectionFromTeamControl = new Control({element: this._removeSelectionFromTeamButton.nativeElement});

    this.karte.getMap().addControl(this._addSelectionToTeamControl);
    this.karte.getMap().addControl(this._removeSelectionFromTeamControl);
  }

  private addSelection(): void {
    this._controlSelect = new Select({
      style: (feature) => {
        if(!feature.get("data").arbeitsauftrageVorhanden) {
            const color = feature.get("data").prioritaet.color;
          const selectedStyle = new Style({
            image: new RegularShape({
              radius: 11,
              points: 3,
              fill: new Fill({
                color: color,
              }),
              stroke: new Stroke({
                color: "rgba(0, 255, 0, 1)",
                width: 3,
              })
            })
          });
          return selectedStyle;
        } else {
          const selectedStyle = new Style({
            image: new CircleStyle({
              radius: 9,
              fill: new Fill({
                color: "rgba(0, 0, 255, 1.0)",
              }),
              stroke: new Stroke({
                color: "rgba(0, 255, 0, 1.0)",
                width: 3,
              }),
            }),
          });
          return selectedStyle;
        }
      },
      hitTolerance: 10,
      toggleCondition: singleClick,
    });
    this.karte.getMap().addInteraction(this._controlSelect);

    this._selectedFeatures = this._controlSelect.getFeatures();




    this._controlDragBox = new DragBox({
      condition: platformModifierKeyOnly,
    });
    this._controlDragBox.on('boxend', () => {
      const boxExtent = this._controlDragBox.getGeometry().getExtent();

      this._selectedFeatures.extend(
        this._sourceOhneAuftrag.getFeaturesInExtent(boxExtent)
          .filter((feature) => !this._selectedFeatures.getArray().includes(feature) && feature.getGeometry()?.intersectsExtent(boxExtent))
      );
      this._selectedFeatures.extend(
        this._sourceMitAuftrag.getFeaturesInExtent(boxExtent)
          .filter((feature) => !this._selectedFeatures.getArray().includes(feature) && feature.getGeometry()?.intersectsExtent(boxExtent))
      )
    });

    this.karte.getMap().addInteraction(this._controlDragBox);

  }

}
