import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-take-photo',
  templateUrl: './take-photo.component.html',
  styleUrls: ['./take-photo.component.scss']
})
export class TakePhotoComponent {

  videoOptions: MediaTrackConstraints = {
    width: {
      min: 640,
      ideal: 1920,
    },
    height: {
      min: 480,
      ideal: 1080,
    },
    facingMode: "environment",
  };

  trigger: Subject<void> = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<TakePhotoComponent>) {}

  imageClicked(): void {
    this.trigger.next();
  }

  imageCapture(e: WebcamImage): void {
    this.dialogRef.close(e);
  }

  triggerCapture(): Observable<void> {
    return this.trigger.asObservable();
  }
}
