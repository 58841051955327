import { AfterViewInit, Component } from '@angular/core';
import { MsalBroadcastService, MsalGuard, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { SecurityService } from '../services/security.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(public securityService: SecurityService) {}

}
