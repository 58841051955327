import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WakeLockService {

  wakeLock!: WakeLockSentinel;

  constructor() { }

  public async request() {
    try {
      if(this.wakeLock && !this.wakeLock.released) return;
      console.log("WakeLock requested");
      this.wakeLock = await navigator.wakeLock.request("screen");
      this.wakeLock.addEventListener("release", () => {
        console.log("WakeLock released:", this.wakeLock.released);
      });
    }
    catch(err) {
      console.error("WakeLock error:", err);
    }
  }

  public release(): void {
    if(this.wakeLock && !this.wakeLock.released) {
      this.wakeLock.release();
    }
  }
}
