import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateTeamService {

  constructor() { }

  get hasTeamSelected(): boolean {
    return this.id !== undefined;
  }

  set id(e: string) {
    localStorage.setItem("teamId", e);
  }
  get id(): string | null {
    return localStorage.getItem("teamId");
  }

  set name(e: string) {
    localStorage.setItem("teamName", e);
  }
  get name(): string | null {
    return localStorage.getItem("teamName");
  }
}
