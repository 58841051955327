import { Injectable } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class FotoService {

  constructor(private apollo: Apollo) { }

  public findAllFotosBySchadenId(schadenId: string): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findSchadenById($schadenId: ID!) {
          findSchadenById(id: $schadenId) {
            id
            fotos {
              id
              foto
            }
          }
        }
      `,
      variables: {
        schadenId: schadenId,
      }
    });
  }

  public findFirstFotoBySchaden(schadenId: string): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findFirstFotoBySchaden($schadenId: ID!) {
          findFirstFotoBySchaden(schadenId: $schadenId) {
            id
            foto
          }
        }
      `,
      variables: {
        schadenId: schadenId
      }
    });
  }
}
