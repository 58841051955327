<div class="form">
  <div class="sticky-form-buttons">
    <button mat-flat-button color="primary" (click)="openTakePhoto(schadenId)">Weiteres Foto erstellen</button>
  </div>
  <div class="foto">
    @for (foto of $any(fotos | async) ; track foto.id) {
      <img [src]="foto.foto">
    }
  </div>
</div>
