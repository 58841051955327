import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { BasisKarteComponent } from '../basis-karte/basis-karte.component';
import { SelectedFeatureEvent } from 'src/app/types/selected-feature-event';
import { Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';

@Component({
  selector: 'app-zubegehen-abschnitt-menu',
  templateUrl: './zubegehen-abschnitt-menu.component.html',
  styleUrls: ['./zubegehen-abschnitt-menu.component.scss']
})
export class ZubegehenAbschnittMenuComponent implements OnDestroy, AfterViewInit {
  @Input()
  karte!: BasisKarteComponent;

  @Output()
  newSchadenCallback: EventEmitter<SelectedFeatureEvent> = new EventEmitter();

  @Output()
  closeAbschnittCallback: EventEmitter<SelectedFeatureEvent> = new EventEmitter();

  @ViewChild("abschnittMenu", {static: true})
  _abschnittMenu!: ElementRef;

  event: SelectedFeatureEvent|undefined;

  _overlay!: Overlay;
  _title: string = "";

  constructor() {}

  ngOnDestroy(): void {
    this.karte.getMap().removeOverlay(this._overlay);
  }

  ngAfterViewInit(): void {
    this.initOverlay();
  }

  initOverlay(): void {
    this._overlay = new Overlay({
      element: this._abschnittMenu.nativeElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    this.karte.getMap().addOverlay(this._overlay);
  }

  public selectedFeatureHandle(event: SelectedFeatureEvent): void {
    if(event) {
      this.event = event;
      this.showPopup(event.coordinate);
      this._title = event.feature.get("bezeichnung");
    } else {
      this.event = undefined;
      this.closePopup();
    }
  }

  public showPopup(coord: Coordinate): void {
    this._overlay.setPosition(coord);
  }

  public closePopup(): void {
    this._overlay.setPosition(undefined);
  }

  onNewSchaden(): void {
    if(this.event) {
      this.newSchadenCallback.emit(this.event);
    }
  }
  onCloseAbschnitt(): void {
    if(this.event) {
      this.closeAbschnittCallback.emit(this.event);
    }
  }
}
