import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WebcamImage } from 'ngx-webcam';
import { map, take, tap } from 'rxjs';
import { TakePhotoComponent } from 'src/app/foto/take-photo/take-photo.component';
import { PrioritaetService } from 'src/app/services/prioritaet.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { SchadenStateService } from 'src/app/services/schaden-state.service';
import { SchadenartService } from 'src/app/services/schadenart.service';
import { TeilanlageService } from 'src/app/services/teilanlage.service';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent implements OnInit, OnDestroy {

  _basicForm = new FormGroup({
    prioritaet: new FormControl("", Validators.required),
    teilanlage: new FormControl("", Validators.required),
    schadenart: new FormControl("", Validators.required),
  });

  _extraForm = new FormGroup({
    laenge: new FormControl(),
    breite: new FormControl(),
    tiefe: new FormControl(),
    bemerkung: new FormControl(),
  });

  _teilanlagen: any;
  _prioritaeten: any;
  _schadensarten: any;

  _fotosValid: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<CreateDialogComponent>,
    private dialog: MatDialog,
    private progressBarService: ProgressBarService,
    private teilanlageService: TeilanlageService,
    private prioritaetService: PrioritaetService,
    private schadenartService: SchadenartService,
    public schadenStateService: SchadenStateService) {}

  ngOnInit(): void {
    this._schadensarten = this.schadenartService.findAllSchadenarten().valueChanges.pipe(take(1), map(result => result.data.findAllSchadenarten));
    this._teilanlagen = this.teilanlageService.findAllTeilanlagen().valueChanges.pipe(take(1), map(result => result.data.findAllTeilanlagen));
    this._prioritaeten = this.prioritaetService.findAllPrioritaeten().valueChanges.pipe(take(1), map(result => result.data.findAllPrioritaeten));

    if(this.schadenStateService.schadenartId) this._basicForm.get("schadenart")?.setValue(this.schadenStateService.schadenartId);
    if(this.schadenStateService.teilanlageId) this._basicForm.get("teilanlage")?.setValue(this.schadenStateService.teilanlageId);
    if(this.schadenStateService.prioritaetId) this._basicForm.get("prioritaet")?.setValue(this.schadenStateService.prioritaetId);
  }

  ngOnDestroy(): void {
    
  }

  onOkay(): void {
    this.saveSchadenValuesInState();

    this.dialogRef.close("submit");
  }

  onCancel(): void {
    this.dialogRef.close("cancel");
  }

  openTakePhoto(): void {
    const dialogRef = this.dialog.open(TakePhotoComponent);
    dialogRef.afterClosed().subscribe((event: WebcamImage) => {
      this.schadenStateService.fotos.push(event.imageAsDataUrl);
    });
  }

  uploadPhoto(e: any): void {
    this.schadenStateService.fotos.push(e);
  }

  formsValid(): boolean {
    return this._basicForm.valid && this._extraForm.valid && this.schadenStateService.fotos.length>0;
  }

  saveSchadenValuesInState(): void {
    this.schadenStateService.schadenartId = this._basicForm.value.schadenart;
    this.schadenStateService.teilanlageId = this._basicForm.value.teilanlage;
    this.schadenStateService.prioritaetId = this._basicForm.value.prioritaet;

    this.schadenStateService.laenge = this._extraForm.value.laenge;
    this.schadenStateService.breite = this._extraForm.value.breite;
    this.schadenStateService.tiefe = this._extraForm.value.tiefe;
    this.schadenStateService.bemerkung = this._extraForm.value.bemerkung;
  }
}
