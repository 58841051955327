import { Injectable } from '@angular/core';

import * as VERSION from 'src/git-version.json';

@Injectable({
  providedIn: 'root'
})
export class VersionInfoService {

  version: any;

  constructor() {
    this.version = VERSION;
  }

  get dirty(): boolean {
    return this.version.dirty;
  }

  get raw(): string {
    return this.version.raw;
  }

  get hash(): string {
    return this.version.hash;
  }

  get distance(): number|null {
    return this.version.distance;
  }

  get tag(): string|null {
    return this.version.tag;
  }

  get semver(): string|null {
    return this.version.semver;
  }

  get suffix(): string|null {
    return this.version.suffix;
  }

  get semverString(): string|null {
    return this.version.semverString;
  }

  get buildDate(): Date {
    return this.version.buildDate;
  }
}
