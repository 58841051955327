import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasisKarteComponent } from '../basis-karte/basis-karte.component';
import { SchadenService } from 'src/app/services/schaden.service';
import { QueryRef } from 'apollo-angular';
import { Subject, Subscription, take, tap } from 'rxjs';
import { Collection, Feature, Overlay } from 'ol';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Select } from 'ol/interaction';
import { Coordinate } from 'ol/coordinate';
import { MatDialog } from '@angular/material/dialog';
import { FullscreenPhotoComponent } from 'src/app/foto/fullscreen-photo/fullscreen-photo.component';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-schaden-layer',
  templateUrl: './schaeden-layer.component.html',
  styleUrls: ['./schaeden-layer.component.scss']
})
export class SchaedenLayerComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  karte!: BasisKarteComponent;

  @Input()
  tage: number = 90;

  @Input()
  inaktive: boolean = true;

  @Input()
  tolerance: number = 12;

  @Input()
  zIndex: number = 10;

  @ViewChild("schadenPopup", {static: true})
  _schadenPopup!: ElementRef;

  _loading: Subject<boolean> = new Subject<boolean>();
  _selectedSchaden: any;

  _features: Collection<Feature> = new Collection();

  _refQuery!: QueryRef<any>;
  _subQuery!: Subscription;

  _layerSchaden!: VectorLayer<VectorSource>;
  _sourceSchaden!: VectorSource;

  _overlaySchaden!: Overlay;
  _selectSchaden!: Select;

  _styleActive: Style = new Style({
    image: new CircleStyle({
      radius: 14,
      fill: new Fill({
        color: "rgba(0, 255, 0, 0.5)"
      }),
      stroke: new Stroke({
        color: "rgba(255, 255, 255, 0.5)",
        width: 2,
      }),
    }),
  });
  _styleInactive: Style = new Style({
    image: new CircleStyle({
      radius: 14,
      fill: new Fill({
        color: "rgba(255, 0, 0, 0.5)"
      }),
      stroke: new Stroke({
        color: "rgba(255, 255, 255, 0.5)",
        width: 2,
      }),
    }),
  });

  constructor(private schadenService: SchadenService,
    private progressBarService: ProgressBarService,
    private dialog: MatDialog) {}

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.initSchadenLayer();

    this.loadData();
    this.initOverlay();
  }

  ngOnDestroy(): void {
    this.karte.getMap().removeOverlay(this._overlaySchaden);
    this.karte.getMap().removeLayer(this._layerSchaden);
    this.karte.getMap().removeInteraction(this._selectSchaden);
  }

  initSchadenLayer(): void {
    this._sourceSchaden = new VectorSource({
      features: this._features,
    });
    this._layerSchaden = new VectorLayer({
      source: this._sourceSchaden,
      style: this._styleActive,
      zIndex: this.zIndex,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    this.karte.getMap().addLayer(this._layerSchaden);


    this._selectSchaden = new Select({
      layers: [this._layerSchaden],
      hitTolerance: this.tolerance,
    });

    this.karte.getMap().addInteraction(this._selectSchaden);

    this._selectSchaden.on("select", (evt) => {
      console.debug("Schaden select:", evt);
      if(evt.selected.length>0) {
        this.openPopup(evt.mapBrowserEvent.coordinate, evt.selected[0].getId() as string);
      } else {
        this.closePopup();
      }
    });
  }

  initOverlay(): void {
    this._overlaySchaden = new Overlay({
      element: this._schadenPopup.nativeElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    this.karte.getMap().addOverlay(this._overlaySchaden);
  }

  loadData(): void {
    this.progressBarService.add();
    this._refQuery = this.schadenService.findAllSchadenInDaysSimple(this.tage, this.inaktive);
    this._subQuery = this._refQuery.valueChanges.pipe(untilDestroyed(this)).subscribe(result => {
      this.progressBarService.remove();
      console.log("Schaden query loaded");
      this._features.clear();

      const proj = this.karte.getView().getProjection();

      for(const data of result.data.findAllSchaedenInDays) {
        const feature = new Feature();
        feature.setId(data.id);
        if(!data.active) {
          feature.setStyle(this._styleInactive);
        }
        feature.setProperties(data);
        feature.setGeometry(new Point(fromLonLat([data.lon, data.lat], proj)));

        this._features.push(feature);
      }
    });
  }

  openPopup(coord: Coordinate, schadenId: string): void {
    this._overlaySchaden.setPosition(coord);
    this._loading.next(true);

    this.progressBarService.add();
    this.schadenService.findSchadenById(schadenId).valueChanges.pipe(take(1), tap(() => this.progressBarService.remove())).subscribe(result => {
      console.debug("Schaden loaded:", result);
      this._selectedSchaden = result.data.findSchadenById;
      this._loading.next(false);
    });
  }

  closePopup(): void {
    this._overlaySchaden.setPosition(undefined);
  }

  displayFoto(foto: string): void {
    const dialogRef = this.dialog.open(FullscreenPhotoComponent, {
      width: "100%",
      height: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      data: {
        foto: foto,
      }
    });
  }

  public refetch(): void {
    this._refQuery.refetch();
  }
}
