import { Injectable } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class BezirkService {

  constructor(private apollo:Apollo) { }

  findAllBezirke(): QueryRef<any> {
    return this.apollo.watchQuery({
      query: gql`
      query findAllBezirke {
        findAllBezirke {
          id
          name
        }
      }
      `
    });
  }


  findBezirkById(id: string = ""): QueryRef<any, {id: string}> {
    return this.apollo.watchQuery({
      query: gql`
      query findBezirkById($id: ID!) {
        findBezirkById(id: $id) {
          id
          name
          strassenabschnitte {
            id
            bezeichnung
            strasse {
              id
              name
            }
          }
        }
      }
      `,
      variables: {
        id: id,
      }
    });
  }
}
