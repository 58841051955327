import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QueryRef } from 'apollo-angular';
import { map, take, tap } from 'rxjs';
import { DeleteSchadenDialogComponent } from 'src/app/helper/delete-schaden-dialog/delete-schaden-dialog.component';
import { QuestionDialogComponent } from 'src/app/helper/question-dialog/question-dialog.component';
import { CreateArbeitsauftragDialogComponent } from 'src/app/meister/arbeitsauftrag/create-arbeitsauftrag-dialog/create-arbeitsauftrag-dialog.component';
import { ArbeitsauftragService } from 'src/app/services/arbeitsauftrag.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { SchadenService } from 'src/app/services/schaden.service';
import { SecurityService } from 'src/app/services/security.service';

@UntilDestroy()
@Component({
  selector: 'app-list-schaden',
  templateUrl: './list-schaden.component.html',
  styleUrls: ['./list-schaden.component.scss']
})
export class ListSchadenComponent implements OnInit, OnDestroy {

  schaeden: any;
  displayedColumns = [
    "datum",
    "prioritaet",
    "teilanlage",
    "schadenart",
    "strassenabschnitt",
    "arbeitsauftrageVorhanden",
    "tools",
  ];

  schadenQueryRef!: QueryRef<any>;

  constructor(
    private schadenService: SchadenService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private arbeitsauftragService: ArbeitsauftragService,
    public securityService: SecurityService,
    public progressBarService: ProgressBarService) {
  }

  ngOnInit(): void {
    this.schadenQueryRef = this.schadenService.findAllSchaeden();
    this.progressBarService.add();
    this.schaeden = this.schadenQueryRef.valueChanges.pipe(untilDestroyed(this), map(result => result.data.findAllSchaeden), tap(() => this.progressBarService.remove()));
  }

  ngOnDestroy(): void {
    
  }

  getStrassenabschnittBezeichnung(schaden: any): string|null {
    if(schaden.strassenabschnitt?.bezeichnung) {
      return schaden.strassenabschnitt.bezeichnung;
    }
    if(schaden.zubegehenStrassenabschnitt) {
      return schaden.zubegehenStrassenabschnitt?.bezeichnung;
    }
    return null;
  }

  deleteSchaden(schaden: any) {
    const dialogRef = this.dialog.open(DeleteSchadenDialogComponent,{
      data: {
        id: schaden.id,
        datum: schaden.datum,
        prioritaet: schaden.prioritaet.name,
        teilanlage: schaden.teilanlage.name,
        strassenabschnitt: schaden.strassenabschnitt ? schaden.strassenabschnitt?.bezeichnung : schaden.zubegehenStrassenabschnitt?.bezeichnung,
        userCreated: schaden.userCreated,
        createdAt: schaden.createdAt,
      }
    });
    dialogRef.afterClosed().subscribe(r => {
      if(r?.result) {
        this.schadenService.delete(schaden.id).pipe(take(1)).subscribe({
          next: result=>{
            console.log("Deleted schaden:", result);
            this.schadenQueryRef.refetch();
          }
        });
      }
    });
  }

  createArbeitsauftrag(schaden: any): void {
    console.debug("Erstelle Arbeitsauftrag");
    const dialogRef = this.dialog.open(CreateArbeitsauftragDialogComponent, {
      data: {
        schaden: schaden,
      }
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if(result && result.teamId) {
          console.log(result);
          this.progressBarService.add();
          this.arbeitsauftragService.createArbeitsauftrag(result.teamId, schaden.id, result.beschreibung).pipe(take(1)).subscribe({
            next: () => {
              this.progressBarService.remove();
              this.schadenQueryRef.refetch();
            },
            error: (err) => {
              this.progressBarService.remove();
              this.snackBar.open("Fehler beim Speichern: "+ err, "Ok");
            }
          });
        }
      },
    });
  }

  deleteArbeitsauftrag(schaden: any): void {
    console.debug("Lösche Arbeitsauftrag");
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      data: {
        title: "Arbeitsauftrag entfernen?",
        message: "Soll der Arbeitsauftrag auf den Schaden entfernt werden?"
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result && result.result) {
        this.progressBarService.add();
        this.arbeitsauftragService.deleteArbeitsauftragBySchadenId(schaden.id).pipe(take(1)).subscribe({
          next: (result) => {
            this.progressBarService.remove();
            console.debug("deleteArbeitsauftragBySchadenId: ", result);
            this.schadenQueryRef.refetch();
          },
          error: (err) => {
            this.progressBarService.remove();
            console.error(err);
            this.snackBar.open("Fehler beim Löschen des Auftrages: " +err, "Ok");
          }
        });
      }
    });
  }
}
