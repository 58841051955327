import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamListComponent } from './teams/team-list/team-list.component';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TeamCreateDialogComponent } from './teams/team-create-dialog/team-create-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CreateArbeitsauftragDialogComponent } from './arbeitsauftrag/create-arbeitsauftrag-dialog/create-arbeitsauftrag-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { KarteModule } from '../karte/karte.module';
import { RouterModule } from '@angular/router';
import { TeamKarteSchadenComponent } from './teams/team-karte-schaden/team-karte-schaden.component';
import { TeamSchadenListComponent } from './teams/team-schaden-list/team-schaden-list.component';
import { TeamLayerSchaedenComponent } from './teams/team-layer-schaeden/team-layer-schaeden.component';
import { MatExpansionModule } from '@angular/material/expansion';



@NgModule({
  declarations: [
    TeamListComponent,
    TeamCreateDialogComponent,
    TeamKarteSchadenComponent,
    TeamSchadenListComponent,
    TeamLayerSchaedenComponent,
    CreateArbeitsauftragDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    KarteModule,
  ]
})
export class MeisterModule { }
