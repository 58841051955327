<h1 mat-dialog-title>Neuer Schaden</h1>

<div mat-dialog-content>
    <mat-accordion>
        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>Fotos</mat-expansion-panel-header>
            @for (f of (_fotos | async)?.fotos; track f.id) {
                <img class="photo" src="{{f.foto}}">
        }
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>Informationen</mat-expansion-panel-header>
            <mat-form-field class="prioritaet">
                <mat-label>Priorität</mat-label>
                <input matInput readonly="true" value="{{(_schaden | async)?.prioritaet.name}}">
            </mat-form-field>

            <br/>

            <mat-form-field class="strasse">
                <mat-label>Straßenabschnitt</mat-label>
                <input matInput readonly="true" value="{{getStrassenabschnitt(_schaden | async)}}">
            </mat-form-field>

            <br/>

            <mat-form-field class="teilanlage">
                <mat-label>Teilanlage</mat-label>
                <input matInput readonly="true" value="{{(_schaden | async)?.teilanlage.name}}">
            </mat-form-field>
            <mat-form-field class="schadenart">
                <mat-label>Schadenart</mat-label>
                <input matInput readonly="true" value="{{(_schaden | async)?.schadenart.name}}">
            </mat-form-field>

            <mat-form-field class="laenge">
                <mat-label>Länge</mat-label>
                <input matInput type="number" readonly="true" value="{{(_schaden | async)?.laenge}}">
            </mat-form-field>

            <mat-form-field class="breite">
                <mat-label>Breite</mat-label>
                <input matInput type="number" readonly="true" value="{{(_schaden | async)?.breite}}">
            </mat-form-field>

            <mat-form-field class="tiefe">
                <mat-label>Tiefe</mat-label>
                <input matInput type="number" readonly="true" value="{{(_schaden | async)?.tiefe}}">
            </mat-form-field>

        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>Aufnahmedaten</mat-expansion-panel-header>
            <mat-form-field class="user">
                <mat-label>Erstellt von</mat-label>
                <input matInput readonly="true" value="{{(_schaden | async)?.userCreated}}">
            </mat-form-field>
            <mat-form-field class="date">
                <mat-label>Erstellt am</mat-label>
                <input matInput readonly="true" value="{{(_schaden | async)?.createdAt | date: 'dd.MM.yyyy HH:mm:ss'}}">
            </mat-form-field>

            <br/>

            <mat-form-field class="user">
                <mat-label>Geändert von</mat-label>
                <input matInput readonly="true" value="{{(_schaden | async)?.userModified}}">
            </mat-form-field>
            <mat-form-field class="date">
                <mat-label>Geändert am</mat-label>
                <input matInput readonly="true" value="{{(_schaden | async)?.modifiedAt | date: 'dd.MM.yyyy HH:mm:ss'}}">
            </mat-form-field>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>Schaden schließen</mat-expansion-panel-header>
            <form>
                <mat-form-field class="bemerkung">
                    <mat-label>Bemerkung</mat-label>
                    <textarea matInput [(ngModel)]="_bemerkung" name="bemerkung"></textarea>
                </mat-form-field>

                <div>
                    <button mat-flat-button color="primary" (click)="openTakePhoto()">Foto erstellen</button>
                    <app-upload-photo class="upload-button" (uploadedImage)="uploadPhoto($event)"></app-upload-photo>
                </div>
                <div>
                    @for (img of _takenFotos(); track $index) {
                        <img [src]="img" width="200px" class="photo">
                    }
                </div>
                
                <br/>
                <mat-checkbox [(ngModel)]="_erledigt" name="erledigt">Reparatur erledigt</mat-checkbox>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div mat-dialog-actions class="buttons">
    <div></div>
    <div class="right-buttons">
        <button mat-button color="warn" (click)="dialogRef.close()">Abbruch</button>
        <button mat-button color="warn" (click)="save()" [disabled]="!saveEnabled()">Speichern</button>
    </div>
</div>
