import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QueryRef } from 'apollo-angular';
import { map, take, tap } from 'rxjs';
import { QuestionDialogComponent } from 'src/app/helper/question-dialog/question-dialog.component';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { SchadenService } from 'src/app/services/schaden.service';
import { ZubegehenStrassenabschnittService } from 'src/app/services/zubegehen-strassenabschnitt.service';

@UntilDestroy()
@Component({
  selector: 'app-list-zubegehn-strassenabschnitte',
  templateUrl: './list-zubegehn-strassenabschnitte.component.html',
  styleUrls: ['./list-zubegehn-strassenabschnitte.component.scss']
})
export class ListZubegehnStrassenabschnitteComponent implements OnInit, OnDestroy {

  begehungId!: string;

  skQueryRef!: QueryRef<any>;
  skList: any;
  displayedColumns = ["bezeichnung", "erledigtAt", "tools"];

  constructor(private zubegehenStrassenabschnittService: ZubegehenStrassenabschnittService,
    private schadenService: SchadenService,
    private progressBarService: ProgressBarService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) {
    let begehungId = this.route.snapshot.paramMap.get("begehungId");
    if(begehungId) this.begehungId = begehungId;
  }

  ngOnInit(): void {
    this.progressBarService.clear();
    
    this.skQueryRef = this.zubegehenStrassenabschnittService.findZubegehenStrassenabschnittByBegehungId(this.begehungId);

    this.progressBarService.add();
    this.skList = this.skQueryRef.valueChanges.pipe(map(d => d.data.findZubegehenStrassenabschnittByBegehungId), tap(() => this.progressBarService.remove()));

    this.schadenService.backlink = ["/begehung/offen/", this.begehungId];
  }

  ngOnDestroy(): void {
    
  }

  closeZubegehen(sk: any): void {
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      data: {
        title: "Straßenabschnitt schließen",
        message: "Soll der Straßenabschnitt '"+ sk.bezeichnung +"' geschloßen werden?",
      }
    });

    dialogRef.afterClosed().subscribe(r => {
      if(r?.result) {
        this.progressBarService.add();
        this.zubegehenStrassenabschnittService.closeZubegehenStrassenabschnitt(sk.id).pipe(take(1), tap(() => this.progressBarService.remove())).subscribe({
          next: result => {
            this.snackBar.open("Straßenabschnitt geschloßen", "", {duration: 1000});
            this.skQueryRef.refetch();
          },
          error: err => {
            this.snackBar.open("Fehler beim Speichern: "+ err, "OK");
          },
        });
      }
    });
  }
}
