import { Injectable } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class StrassenabschnittService {

  constructor(private apollo: Apollo) { }

  findAllStrassenabschnitte(): QueryRef<any> {
    return this.apollo.watchQuery({
      query: gql`
      query findAllStrassenabschnitte {
        findAllStrassenabschnitte {
          id
          bezeichnung
        }
      }
      `
    });
  }

  findAllStrassenabschnitteWithGeom(): QueryRef<any> {
    return this.apollo.watchQuery({
      query: gql`
      query findAllStrassenabschnitteWithGeom {
        findAllStrassenabschnitte {
          id
          bezeichnung
          geometry
        }
      }
      `
    });
  }

  findByBezeichnung(s: string = ""): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
      query strassenabschnitteDropdown($bezeichnung: String!) {
        findStrassenabschnitteByName(bezeichnung: $bezeichnung) {
          id
          bezeichnung
        }
      }
      `,
      variables: {
        bezeichnung: s,
      }
    });
  }

  findByBezeichnungFilterd(s: string = ""): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
      query strassenabschnitteDropdown($bezeichnung: String!) {
        findStrassenabschnitteByName(bezeichnung: $bezeichnung) {
          id
          bezeichnung
        }
      }
      `,
      variables: {
        bezeichnung: s,
      }
    });
  }
}
