import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasisKarteComponent } from './basis-karte/basis-karte.component';
import { OsmLayerComponent } from './osm-layer/osm-layer.component';
import { LuftbilderLayerComponent } from './luftbilder-layer/luftbilder-layer.component';
import { GpsTrackingComponent } from './gps-tracking/gps-tracking.component';
import { MatIconModule } from '@angular/material/icon';
import { SelectAbschnittComponent } from './select-abschnitt/select-abschnitt.component';
import { MatButtonModule } from '@angular/material/button';
import { SelectZubegehenStrassenabschnittComponent } from './select-zubegehen-strassenabschnitt/select-zubegehen-strassenabschnitt.component';
import { SchaedenLayerComponent } from './schaeden-layer/schaeden-layer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AbschnittMenuComponent } from './abschnitt-menu/abschnitt-menu.component';
import { CreateSchadenComponent } from './create-schaden/create-schaden.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ZubegehenAbschnittMenuComponent } from './zubegehen-abschnitt-menu/zubegehen-abschnitt-menu.component';
import { FotoModule } from '../foto/foto.module';



@NgModule({
  declarations: [
    BasisKarteComponent,
    OsmLayerComponent,
    LuftbilderLayerComponent,
    GpsTrackingComponent,
    SelectAbschnittComponent,
    SelectZubegehenStrassenabschnittComponent,
    SchaedenLayerComponent,
    AbschnittMenuComponent,
    CreateSchadenComponent,
    ZubegehenAbschnittMenuComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    FotoModule,
  ],
  exports: [
    BasisKarteComponent,
    OsmLayerComponent,
    LuftbilderLayerComponent,
    SchaedenLayerComponent,
    GpsTrackingComponent,
    SelectAbschnittComponent,
    SelectZubegehenStrassenabschnittComponent,
    AbschnittMenuComponent,
    ZubegehenAbschnittMenuComponent,
    CreateSchadenComponent,
  ]
})
export class KarteModule { }
