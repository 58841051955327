import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TakePhotoComponent } from './take-photo/take-photo.component';
import { FullscreenPhotoComponent } from './fullscreen-photo/fullscreen-photo.component';
import { WebcamModule } from 'ngx-webcam';



@NgModule({
  declarations: [
    TakePhotoComponent,
    FullscreenPhotoComponent,
  ],
  imports: [
    CommonModule,
    WebcamModule,
  ],
  exports: [
    TakePhotoComponent,
    FullscreenPhotoComponent,
  ]
})
export class FotoModule { }
