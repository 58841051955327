<div mat-dialog-content>
    <table>
        <tr><th>Artifact:</th><td>{{(backendVersionInfo|async)?.artifact}}</td></tr>
        <tr><th>Group:</th><td>{{(backendVersionInfo|async)?.group}}</td></tr>
        <tr><th>Name:</th><td>{{(backendVersionInfo|async)?.name}}</td></tr>
        <tr><th>Time:</th><td>{{(backendVersionInfo|async)?.time}}</td></tr>
        <tr><th>Version:</th><td>{{(backendVersionInfo|async)?.version}}</td></tr>
    </table>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="closeDialog()">Okay</button>
</div>
