import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { ProgressBarService } from './services/progress-bar.service';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { BackendVersionInfoComponent } from './version/backend-version-info/backend-version-info.component';
import { SecurityService } from './services/security.service';
import { SidenavService } from './services/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { WakeLockService } from './services/wake-lock.service';
import { StateTeamService } from './services/state-team.service';
import { MonitorService } from './services/monitor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'strassenschaeden-fe';

  showSidenav: boolean = false;
  @ViewChild("sidenav") public sidenav!: MatSidenav;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private dialog: MatDialog,
    private swUpdate: SwUpdate,
    private snackBar: MatSnackBar,
    public securityService: SecurityService,
    public progressBarService: ProgressBarService,
    public sidenavService: SidenavService,
    private router: Router,
    private wakeLockService: WakeLockService,
    public stateTeamService: StateTeamService,
    private monitorService: MonitorService,
  ) {
  }

  ngOnInit(): void {
    this.swUpdate.versionUpdates.subscribe((result) => {
      console.debug("SWUpdate:", result);
      if(result.type==='VERSION_READY') {
        let ref = this.snackBar.open("Eine aktuelle Version liegt vor. Anwendung bitte neuladen.", "Neuladen");
        ref.afterDismissed().subscribe((reload) => {
          location.reload();
        });
      }
    });

    console.log("Auth:", this.authService);
    
    //this.addWakeLockHandle();
  }

  private addWakeLockHandle(): void {
    this.wakeLockService.request();

    document.addEventListener("visibilitychange", () => {
      if(document.visibilityState === 'visible') {
        this.wakeLockService.request();
      }
    });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);

    this.router.events.subscribe(event => {
      this.sidenavService.close();
    });
  }

  get loginDisplay(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  loginRedirect(): void {
    if(this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout(popup?: boolean) {
    if(popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  displayBackendVersionInfo(): void {
    console.debug("Open");
    const dialogRef = this.dialog.open(BackendVersionInfoComponent, {

    });
    dialogRef.afterClosed().subscribe(r => {});
  }
}
