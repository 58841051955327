import { Injectable } from '@angular/core';
import { Apollo, MutationResult, QueryRef, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BegehungService {

  constructor(private apollo: Apollo) {}

  findAllBegehungen(limit: number|null = null, cache: boolean = false): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findAllBegehungen(
          $limit: Int
        ) {
          findAllBegehungen(
            limit: $limit
          ) {
            id
            datum
            userCreated
            name
          }
        }
      `,
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
      variables: {
        limit: limit
      },
    });
  }

  createBegehung(bezrikId: string, datum: string): Observable<MutationResult> {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation createBegehung(
          $bezirkId: ID!,
          $datum: String!,
        ) {
          createBegehung(
            bezirkId: $bezirkId,
            datum: $datum
          ) {
            id
          }
        }
      `,
      variables: {
        bezirkId: bezrikId,
        datum: datum,
      }
    });
  }
}
