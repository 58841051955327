import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StateTeamService } from './services/state-team.service';

export const teamGuard: CanActivateFn = (route, state) => {
  const stateTeamService = inject(StateTeamService);
  const router = inject(Router);

  if(!stateTeamService.hasTeamSelected) {
    router.navigate(["/reparatur"]);
  }

  return true;
};
