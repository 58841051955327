<h1>Teams</h1>
<mat-toolbar color="primary">
    <button mat-fab extended (click)="createTeam()"><mat-icon>group_add</mat-icon>Neues Team</button>
</mat-toolbar>
<table mat-table [dataSource]="teams" class="mat-elevation-z8 sticky-table color-table">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let team">{{team.name}}</td>
    </ng-container>

    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let team">
            <button mat-mini-fab color="primary" [routerLink]="['/teams', team.id, 'schaden', 'list']"><mat-icon>list</mat-icon></button>
            <button mat-mini-fab color="primary" class="left-space" [routerLink]="['/teams', team.id, 'schaden','karte']"><mat-icon>map</mat-icon></button>
            <button mat-mini-fab color="warn" class="left-space" (click)="deleteTeam(team)"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
