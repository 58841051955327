import { Injectable } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class SchadenartService {

  constructor(private apollo: Apollo) { }

  findAllSchadenarten(): QueryRef<any> {
    return this.apollo.watchQuery({
      query: gql`
      query findAllSchadenarten {
        findAllSchadenarten {
          id
          name
        }
      }
      `
    });
  }
}
