@if (securityService.isBegeher()) {
  <div>
    <h1>Aktive Begehungen</h1>
    <app-liste-begehungen></app-liste-begehungen>
  </div>
}
@if (securityService.isStrassenwaerter()) {
  <div>
    <h1>Reparaturen</h1>
    <app-reparatur-list></app-reparatur-list>
  </div>
}
