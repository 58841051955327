import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Apollo } from 'apollo-angular';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent implements OnInit, OnDestroy {

  constructor(
    private apollo: Apollo,
    private progressBarService: ProgressBarService,
    public securityService: SecurityService,
    private msalService: MsalService,
  ) {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  clearApolloCache(): void {
    this.apollo.client.cache.reset();
    this.progressBarService.clear();
  }

  clearMSAL(): void {
    const account = this.msalService.instance.getActiveAccount();
    this.msalService.logoutRedirect({account: account});
  }
}
