import { Injectable } from '@angular/core';
import { Apollo, MutationResult, QueryRef, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZubegehenStrassenabschnittService {

  constructor(private apollo: Apollo) { }

  findAllZubegehenStrassenabschnitte(): QueryRef<any> {
    return this.apollo.watchQuery({
      query: gql`
        query findAllZubegehenStrassenabschnitte {
          findAllZubegehenStrassenabschnitte {
            id
            bezeichnung
            erledigtUser
            erledigtAt
          }
        }
      `,
    })
  }

  findZubegehenStrassenabschnittByBegehungId(begehungId: string): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findZubegehenStrassenabschnittByBegehungId($begehungId: ID!) {
          findZubegehenStrassenabschnittByBegehungId(begehungId: $begehungId) {
            id
            bezeichnung
            erledigtUser
            erledigtAt
          }
        }
      `,
      variables: {
        begehungId: begehungId
      },
    });
  }

  findZubegehenStrassenabschnittGeomByBegehungId(begehungId: string): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: gql`
        query findZubegehenStrassenabschnittGeomByBegehungId($begehungId: ID!) {
          findZubegehenStrassenabschnittByBegehungId(begehungId: $begehungId) {
            id
            bezeichnung
            geometry
            erledigtAt
          }
        }
      `,
      variables: {
        begehungId: begehungId
      },
      errorPolicy: 'all'
    });
  }

  closeZubegehenStrassenabschnitt(id: string): Observable<MutationResult> {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation closeZubegehenStrassenabschnitt($id: ID!) {
          closeZubegehenStrassenabschnitt(id: $id) {
            id
          }
        }
      `,
      variables: {
        id: id
      },
    });
  }
}
