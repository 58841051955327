import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BasisKarteComponent } from '../basis-karte/basis-karte.component';
import { Control } from 'ol/control';
import { Collection, Feature } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Modify } from 'ol/interaction';
import { Circle, Fill, Stroke, Style } from 'ol/style';

@Component({
  selector: 'app-create-schaden',
  templateUrl: './create-schaden.component.html',
  styleUrls: ['./create-schaden.component.scss']
})
export class CreateSchadenComponent implements AfterViewInit {

  @Input()
  karte!: BasisKarteComponent;

  @Input()
  tolerance: number = 20;

  @Input()
  zIndex: number = 20;

  @Output()
  commitSchadenCallback: EventEmitter<Feature> = new EventEmitter();

  @Output()
  cancelSchadenCallback: EventEmitter<null> = new EventEmitter();

  @ViewChild("addSchadenControl", {static: true})
  _addSchadenControl!: ElementRef;
  _controlAdd!: Control;

  @ViewChild("cancelSchadenControl", {static: true})
  _cancelSchadenControl!: ElementRef;
  _controlCancel!: Control;

  _display: string = "none";

  _schadenFeature: Collection<Feature> = new Collection();

  _layer!: VectorLayer<VectorSource>;
  _source!: VectorSource;

  _interModify!: Modify;

  _style: Style = new Style({
    image: new Circle({
      radius: 18,
      stroke: new Stroke({
        color: "#000000",
        width: 2,
      }),
      fill: new Fill({
        color: "rgba(213, 2, 255, 0.5)"
      }),
    }),
  });

  constructor() {}

  ngAfterViewInit(): void {
    this._controlAdd = new Control({
      element: this._addSchadenControl.nativeElement,
    });
    this._controlCancel = new Control({
      element: this._cancelSchadenControl.nativeElement,
    });

    this._source = new VectorSource({
      features: this._schadenFeature,
    });

    this._layer = new VectorLayer({
      source: this._source,
      style: this._style,
      zIndex: this.zIndex,
    });

    this._interModify = new Modify({
      features: this._schadenFeature,
      pixelTolerance: this.tolerance,
    });
  }

  commitSchaden(): void {
    if(this._schadenFeature.getLength()==1) {
      this.commitSchadenCallback.emit(this._schadenFeature.getArray()[0]);
    }
  }

  cancelSchaden(): void {
    this.cancelSchadenCallback.emit();
  }

  public enableControl(): void {
    this._display = "block";
    this.karte.getMap().addControl(this._controlAdd);
    this.karte.getMap().addControl(this._controlCancel);
  }

  public disableControl(): void {
    this._display = "none";
    this.karte.getMap().removeControl(this._controlAdd);
    this.karte.getMap().removeControl(this._controlCancel);
  }

  public addSchadenAt(feature: Feature): void {
    this._schadenFeature.clear();
    this._schadenFeature.push(feature);
  }

  public enableLayer(): void {
    this.karte.getMap().addLayer(this._layer);
  }
  public disableLayer(): void {
    this.karte.getMap().removeLayer(this._layer);
  }

  public enableInteraction(): void {
    this.karte.getMap().addInteraction(this._interModify);
  }
  public disableInteraction(): void {
    this.karte.getMap().removeInteraction(this._interModify);
  }

  public getSchadenFeature(): Feature|undefined {
    if(this._schadenFeature.getLength()==1) {
      return this._schadenFeature.getArray()[0];
    }
    return undefined;
  }
}
